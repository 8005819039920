@use "./variables.scss" as v;
@use "./colors.scss" as c;

.ant-picker {
  height: 38px;
  border: 1px solid c.$gray-dark-1;
  border-radius: v.$border-radius-2;
  background-color: transparent;
  width: 100%;
}

.ant-picker-range {
  .ant-picker-input {
    width: 86px;
    margin-left: v.$margin-3;
  }

  .ant-picker-suffix {
    margin-right: 0;
  }
}

.ant-picker-input > input {
  &::placeholder {
    color: c.$header-light-gray;
    font-size: v.$font-size-sm;
  }
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: c.$gray-dark-1;
}

.ant-picker-focused {
  border-color: c.$gray-dark-1;
  box-shadow: none;
}

.ant-picker-suffix {
  order: -1;
  margin-left: 0;
  margin-right: v.$margin-3;

  svg {
    fill: c.$gray-dark-1;
  }
}

.ant-picker-header {
  border-bottom: none;
  margin-top: v.$margin-5;
}

.ant-picker-header-view {
  font-size: v.$font-size-lg - 1;
  color: c.$black-2;
  font-weight: 700;

  button:hover {
    color: c.$primary;
  }
}
.ant-picker-clear {
  opacity: 1;
  right: 40px !important;
  color: c.$primary;
}

.ant-picker-prev-icon::before,
.ant-picker-next-icon::before {
  height: 12px;
  width: 12px;
  border-color: c.$black-2;
  border-width: 2px 0 0 2px;
}

.ant-picker-range .ant-picker-active-bar {
  background-color: transparent;
}

.ant-picker-content {
  td,
  th {
    font-weight: 500;
    font-size: v.$font-size-xs + 1;
  }

  .ant-picker-cell {
    &-inner,
    &::before {
      min-width: 34px;
      height: 34px;
      line-height: 34px;
    }

    &-disabled {
      color: c.$gray-subdued;
    }
  }
}
.ant-picker-panels {
  padding-bottom: v.$padding-5;
}

.ant-picker-date-panel {
  width: 310px;

  .ant-picker-body {
    padding: v.$padding-2 v.$padding-7;
  }

  table {
    margin: 0 auto;
  }
}

.ant-picker-panel-container {
  border-radius: v.$border-radius-2 - 2;
  position: absolute;
  top: 10px;
  right: 0;

  &::before {
    content: "";
    width: 14px;
    height: 10px;
    background-color: c.$white;
    position: absolute;
    top: -10px;
    right: 10px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 2px solid c.$pale-rose;
  border-radius: v.$border-radius-2 - 2;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start:not(:last-child)
  .ant-picker-cell-inner::after,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end:not(:first-child)
  .ant-picker-cell-inner::after {
  color: c.$primary;
  background: c.$pale-rose;
  border-top: 1px solid c.$primary;
  border-bottom: 1px solid c.$primary;
}

.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start:last-child
  .ant-picker-cell-inner::after {
  display: none !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
  .ant-picker-cell-inner {
  border-left: 1px solid c.$primary;
  border-top: 1px solid c.$primary;
  border-bottom: 1px solid c.$primary;
  border-top-left-radius: v.$border-radius-2 - 2;
  border-bottom-left-radius: v.$border-radius-2 - 2;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-start)
  .ant-picker-cell-inner {
  border-right: 1px solid c.$primary;
  border-top: 1px solid c.$primary;
  border-bottom: 1px solid c.$primary;
  border-top-right-radius: v.$border-radius-2 - 2;
  border-bottom-right-radius: v.$border-radius-2 - 2;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  border-top: 1px solid c.$primary;
  border-bottom: 1px solid c.$primary;
  background: c.$pale-rose;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range .ant-picker-cell-inner {
  color: c.$primary;
}

.ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)
  .ant-picker-cell-inner {
  border: 1px solid c.$primary;
  border-radius: v.$border-radius-2 - 2;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  height: 34px;
  border-top: 1px dashed c.$pale-rose;
  border-bottom: 1px dashed c.$pale-rose;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  border-left: 1px dashed c.$pale-rose;
  border-top-left-radius: v.$border-radius-2 - 2;
  border-bottom-left-radius: v.$border-radius-2 - 2;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr
  > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  border-right: 1px dashed c.$pale-rose;
  border-top-right-radius: v.$border-radius-2 - 2;
  border-bottom-right-radius: v.$border-radius-2 - 2;
}
