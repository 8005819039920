@use "../../styles/variables.scss" as v;
@use "../../styles/colors.scss" as c;

.container {
  position: relative;
  width: 100%;
}

.attributes {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.attributeValues {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  button {
    margin-right: 5px;
  }
}

.attributeName {
  margin-bottom: 5px;
}

.product {
  display: grid;

  > div {
    grid-column-start: 1;
    grid-column-end: 12;
  }

  background-color: white;
  border-radius: v.$border-radius-2;
  padding: v.$padding-14 v.$padding-10;

  gap: 30px;
}

.info {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.general-info {
  width: 100%;

  &__title {
    font-size: v.$font-size-md;
  }

  &__table {
    width: 50%;
    &__row {
      margin: v.$margin-4 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      &__key {
        color: c.$gray;
        font-weight: 600;
      }
      &__value {
        font-weight: 400;
        color: c.$gray;
      }
    }
  }
}

.model3d-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
}

.other-variants {
  grid-column-start: 4 !important;
  grid-column-end: 12 !important;

  padding-top: v.$padding-12;
  &__key-value {
    font-weight: 600;
    button {
      border-radius: 0px;
      color: c.$gray;
    }
  }
}
