@use "../../styles/variables.scss" as v;
@use "../../styles/colors.scss" as c;

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  label {
    font-weight: 700;
    font-size: v.$font-size-sm;
    text-transform: uppercase;
    color: c.$gray-dark;
    margin-bottom: v.$margin-1 + 1;
  }
}

.dropdown-arrow {
  position: absolute;
  top: 44px;
  right: 10px;
  transition: transform 0.4s;
  pointer-events: none;
}

[class*="focused"] + .dropdown-arrow {
  transform: scale(-1);
}
