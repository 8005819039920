@use "./variables.scss" as v;
@use "./colors.scss" as c;

.cc-custom-pagination {
  @extend .cc-custom-list;
  margin-top: v.$margin-4;

  li {
    &:nth-child(2) button {
      border-top-left-radius: v.$border-radius-2;
      border-bottom-left-radius: v.$border-radius-2;

      &.transparent {
        background-color: transparent;

        &:hover {
          background-color: c.$primary !important;
        }
      }
    }

    &:nth-last-child(2) button {
      border-top-right-radius: v.$border-radius-2;
      border-bottom-right-radius: v.$border-radius-2;
    }
  }

  button:not(.jump) {
    height: 38px;
    border-color: transparent;
    background-color: c.$white;
    color: c.$black;
    border-radius: 0;

    &:hover {
      color: c.$white;
      border-radius: v.$border-radius-2;
      background-color: c.$primary;
    }
  }

  &-item-active button:not(.jump) {
    border-radius: v.$border-radius-2;
    background-color: c.$primary;
    color: c.$white;
  }

  &-jump-next {
    margin-right: v.$margin-1;

    & + li button {
      background-color: transparent !important;

      &:hover {
        background-color: c.$primary !important;
      }
    }
  }

  &-jump-prev {
    margin-left: v.$margin-1;
  }

  .jump {
    background-color: transparent;
    color: c.$black;
    padding-left: v.$padding-2;
    padding-right: v.$padding-2;
  }
}
