@use "../../../../styles/colors.scss" as c;
@use "../../../../styles/variables.scss" as v;

.table {
  table {
    thead {
      tr {
        th:nth-child(3) {
          > div {
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: v.$padding-4 v.$padding-5 !important;
        }
      }
    }
  }
}

.image {
  min-width: 60px;
  max-width: 60px;
  max-height: 60px;
  min-height: 60px;
  border-radius: 0px !important;
  object-fit: contain;
}

.name-column {
  display: flex;
  align-items: center;
}

.name-and-desc {
  max-width: calc(100% - 50px);
}

.name {
  margin-bottom: 0;
  font-size: v.$font-size-sm;
  color: c.$black-2;
  width: fit-content;
}

.desc {
  margin-bottom: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-name-wrapper {
  display: flex;
  align-items: center;
  max-width: 20vw;
  min-width: 20vw;

  span {
    margin-left: v.$margin-2;
  }
}

.bold {
  font-weight: bold;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.uppercase {
  text-transform: uppercase;
}
