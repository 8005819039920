@use "../../styles/variables.scss" as v;
@use "../../styles/colors.scss" as c;

.header {
  &[class*="ant-page-header"] {
    padding: 0 0 v.$padding-10 0;

    [class="ant-page-header-content"] {
      padding: 0;
    }
  }

  .first-row {
    margin-bottom: v.$margin-7 + 2;
  }

  .second-row {
    justify-content: space-between;
    flex-direction: row-reverse;
    gap: v.$margin-4;
    &__right {
      display: flex;
      gap: v.$margin-4;
    }
  }

  .filters-btn {
    border: 1px solid c.$gray-light-9;
    color: c.$gray-dark-1;
    margin-left: auto;

    svg {
      fill: c.$black;
    }
  }
}

.title {
  font-size: v.$font-size-xxl;
  color: c.$black-2;
  font-weight: bold;
  text-transform: uppercase;
}

.ant-input-search {
  flex: 0.75;
  width: unset;

  input {
    &[class*="ant-input"] {
      &:hover,
      &:focus {
        border: 1px solid c.$gray-light-5;
        box-shadow: none;
      }
    }

    height: 38px;
    padding-right: v.$padding-14; // absolutely positioned button offset
    border-radius: v.$border-radius-2 !important; //very hard to overwrite otherwise
  }

  [class*="ant-input-search-button"] {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
    border-left: none;
    border-color: transparent;
    border-radius: v.$border-radius-2 !important; //very hard to overwrite otherwise
    background: transparent;
    box-shadow: none;
    height: 38px;

    span {
      display: flex;

      svg {
        fill: c.$black;
        font-size: v.$font-size-lg;
      }
    }
  }
}
