@use "./variables.scss" as v;
@use "./colors.scss" as c;

.rdw-editor-toolbar {
  border-radius: v.$border-radius-2;
  border: 1px solid c.$black;
  margin: -1px -1px 0;
  padding: 0;
  min-height: 38px;
  background-color: c.$gray-background;

  & > * {
    margin-bottom: 0;
    margin-right: v.$margin-1;
  }
}

.rdw-editor-wrapper {
  border: 1px solid black;
  border-radius: v.$border-radius-2;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  margin-top: v.$margin-1 + 1;
}

.rdw-dropdown-wrapper,
.rdw-option-wrapper {
  border: none;
  background: transparent;
  transition: background-color 0.3s;

  &:hover,
  &:active {
    box-shadow: none;
    background-color: transparent;
  }
}

.rdw-dropdown-selectedtext {
  transition: background-color 0.3s;
  border-radius: 2px;

  &:hover,
  &:active {
    box-shadow: none;
    background-color: c.$gray-light-2;
  }
}

.rdw-dropdown-wrapper {
  height: 36px;
  margin: 0;
}

.rdw-block-wrapper .rdw-dropdown-selectedtext {
  border-radius: v.$border-radius-2 2px 2px v.$border-radius-2;
}

.rdw-dropdown-carettoopen,
.rdw-dropdown-carettoclose {
  top: 50%;
  transform: translateY(-50%);
}

.rdw-option-wrapper {
  height: 100%;
  min-width: 36px;

  &:hover,
  &:active {
    background-color: c.$gray-light-2;
  }
}

.rdw-option-active {
  box-shadow: 0px 0px 1px c.$gray-medium-dark inset;
}

.rdw-dropdown-optionwrapper {
  width: 100%;
  margin-top: 2px;

  &,
  &:hover {
    box-shadow: v.$shadow-1;
  }
}

.rdw-link-modal,
.rdw-image-modal {
  height: fit-content;
  box-shadow: v.$shadow-1;
  top: 38px;
  left: 3px;
}

.rdw-image-modal {
  input {
    height: 25px;
    width: 100%;
  }
}

.rdw-link-modal-btn,
.rdw-image-modal-btn {
  transition: all 0.3s;
}

.rdw-editor-main {
  flex: 1;
  padding: 0 v.$padding-3;
}
