@use "../../../styles/variables.scss" as v;
@use "../../../styles/colors.scss" as c;

.container {
  height: fit-content;
  min-height: 100%;
  align-items: center;
  background-color: c.$gray-background;
  border-radius: v.$border-radius-1;
  display: flex;
  position: relative;
  z-index: 0;
}

.form-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 10%;
  width: v.$max-breakpoint-sm;
  max-width: 100%;
  z-index: 0;

  @media (max-width: v.$max-breakpoint-sm) {
    right: 0px;
  }

  @media (min-width: v.$min-breakpoint-sm) and (max-width: v.$max-breakpoint-xl) {
    transform: translateX(50%);
    right: 50%;
  }

  form {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: v.$margin-14;
    background-color: c.$white;
    border-radius: v.$border-radius-2;
    box-shadow: v.$shadow-2;

    & > *:not(:last-child) {
      margin-bottom: v.$margin-7;
    }

    h1 {
      text-align: center;
    }
  }
}

.flex1 {
  flex: 1;
}

.group2996 {
  margin-left: 10%;
  max-height: 100vh;
}

.lock-image {
  align-self: center;
}

.title {
  font-size: v.$font-size-xl;
  font-weight: bold;
  margin: v.$margin-5 0px v.$margin-5 0px;
}

.description {
  text-align: center;
}

.back-to-login {
  text-align: center;
}

.login {
  color: c.$primary;

  &:hover {
    color: c.$primary;
  }
}
