@use "../../../../styles/variables.scss" as v;
@use "../../../../styles/colors.scss" as c;

.container {
  margin: 0 auto;
  max-width: 1000px;
  padding-left: 100px;
}

.card {
  margin-top: v.$margin-7;
}
