@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;

.dragger {
  position: relative;
  background-color: c.$gray-light-8;
  border: 1px dashed c.$gray-dark-1;
  border-radius: v.$border-radius-2 + 2;
  padding: v.$padding-10;
}

.title {
  font-size: v.$font-size-sm + 1;
  color: c.$gray-medium-dark;
  font-weight: 700;
  margin-bottom: v.$margin-2 !important;
}

.upload-card,
.gallery-card {
  padding: v.$padding-15 + 3 v.$padding-12;
  flex: 1;

  > div {
    max-width: 280px;
    margin: 0 auto;
  }
}

.upload-description {
  @extend .title;
  font-weight: 400;
  margin: 0;
}

.card-description {
  color: c.$header-light-gray;
}

.upload-icon {
  fill: c.$gray-light-2;
  margin-bottom: v.$margin-3;
}

.remove-btn {
  position: absolute;
  top: v.$margin-2;
  right: v.$margin-2;
  width: 20px;
  height: 20px;
  padding: 1px;
  z-index: 4;
  display: none;

  svg {
    transform: rotate(45deg);
  }
}

.preview {
  position: absolute;
  overflow: hidden;
  border-radius: v.$border-radius-2 + 2;
  outline: c.$primary solid 1px;
  height: 100%;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover {
    .remove-btn {
      display: flex;
    }
  }
}
