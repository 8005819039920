@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;

.container {
  height: fit-content;
  color: c.$black-2;
}

.card {
  display: flex;
  flex-direction: column;
  gap: v.$margin-1;

  background-color: white;
  border-radius: v.$border-radius-1;
  padding: v.$padding-7;

  &__title {
    color: c.$black;
    font-weight: 600;
    margin-bottom: v.$margin-3;
  }
}

.products {
  margin: v.$margin-5;
}

.total-section {
  width: fit-content;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  gap: v.$margin-4 + 1;

  &__subtotal {
    display: grid;
    grid-template-columns: 1fr 1.5fr;

    > div {
      margin-left: auto;
    }
  }
}

.total {
  font-size: v.$font-size-md + 1;
  color: c.$gray;
  // &__key{
  // }

  &__value {
    font-size: v.$font-size-lg;
    font-weight: 600;
    color: c.$gray-medium-dark-2;
  }
}

.br {
  height: v.$margin-4 + 1;
}
