@use "../../styles/variables.scss" as v;
@use "../../styles/colors.scss" as c;

.container {
  margin-bottom: v.$margin-7;
  min-height: 230px;

  h2 {
    font-weight: 700;
    color: c.$black-2;
    text-transform: uppercase;
    margin-top: v.$margin-3;
  }

  .filters-container {
    & > :nth-last-child(2) {
      max-width: fit-content;
    }
  }

  .btn-container {
    display: flex;
    gap: v.$margin-4;
    width: fit-content;
    margin-left: auto;
    margin-top: v.$margin-7;
    padding: 0 v.$padding-2;

    .btn {
      min-width: 90px;
    }

    .reset-btn {
      @extend .btn;
      gap: v.$margin-1;
      color: c.$header-light-gray;
      border: 1px solid c.$gray-2;
      padding: 0;

      svg {
        fill: currentColor;
        max-width: 13px;
        max-height: 13px;
      }
    }

    .apply-btn {
      @extend .btn;
      border: 1px solid c.$primary;
    }
  }
}
