@use "../../../styles/variables.scss" as v;
@use "../../../styles/colors.scss" as c;

.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: v.$padding-9;
  margin-bottom: 32px;
  width: 100%;
}

.personslct,
.personslct:active,
.personslct:hover {
  width: 23%;
  border-style: none;
  background-color: c.$white;

  // & > div.ant-select.ant-select-single.ant-select-show-arrow {
  //   border: 1px solid c.$gray-light-9;
  //   border-radius: 10px;
  // }
}

.slct,
.slct:active,
.slct:hover {
  background-color: c.$white;
}

.slcts,
.slcts:active,
.slcts:hover {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 17px;

  & input {
    font-size: v.$font-size-sm;
    color: c.$gray-medium-dark-2;
    border: 1px solid c.$gray-light-9;
    border-radius: 10px;
    width: 85px;
    height: 38px;
  }

  .btn-active {
    background-color: c.$gray-light-4;
  }
}

.daypicker,
.daypicker:hover,
.daypicker:active {
  background-color: c.$gray-light-5;
  width: 25%;
  margin-top: -5px;

  & > div {
    border-style: none;

    & > div {
      border: 1px solid c.$gray-light-9;

      & div {
        border-style: none;
      }
    }

    & > svg {
      margin-top: -22px;
    }
  }
}

.indexrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
