@use "../../../styles/variables.scss" as v;
@use "../../../styles/colors.scss" as c;

.label {
  color: c.$gray-medium-dark-2;
  font-weight: 700;
}

.finish-message {
  display: flex;
  align-items: center;
  gap: v.$margin-2;
  &__checkmark {
    color: c.$green;
  }
  &__error {
    fill: c.$primary;
    min-height: 20px;
    min-width: 20px;
  }
}

.progress {
  margin: v.$margin-2 0;
}

.progress-text {
  font-size: v.$font-size-xs;
}

.imported-statistics {
  background-color: c.$gray-alternate;
  padding: v.$padding-4 v.$padding-6;
  margin-top: v.$margin-2;

  div:nth-child(n + 2) {
    margin-top: v.$margin-3;
  }
}

.errors {
  background-color: c.$gray-alternate;
  padding: v.$padding-4 v.$padding-6;
  margin-top: v.$margin-2;

  &__title {
    color: c.$gray;
    font-weight: 700;
  }

  &__table {
    width: 100%;
    tr {
      padding: v.$padding-3;
    }
  }
}

button {
  font-size: v.$font-size-sm;
}

.submit {
  margin-top: v.$margin-2;
}

.notice {
  background-color: c.$gray-alternate;
  padding: v.$padding-4 v.$padding-6;
  border-left: 4px solid c.$primary;
  display: flex;
  align-items: center;
  gap: v.$margin-4;

  svg {
    fill: c.$primary;
    min-height: 20px;
    min-width: 20px;
  }

  a {
    color: c.$primary;
    text-decoration: underline;
    font-weight: 700;
    &:hover {
      color: c.$primary;
    }
  }
}

.upload-label {
  color: c.$gray-medium-dark-2;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: v.$margin-4;
  margin-bottom: -(v.$margin-2);
}

.upload-box {
  border: 1px dashed c.$gray-medium-dark-2;
  border-radius: v.$border-radius-4;
  background-color: c.$gray-alternate;
  display: flex;
  align-items: center;
  gap: v.$margin-6;
  padding: 0 v.$padding-10;
  margin-bottom: v.$margin-6;
  cursor: pointer;

  &:hover {
    border-style: solid;
  }

  &__delete {
    border: none;
    background: none;
    cursor: pointer;
    height: 24px;
    width: 24px;

    svg {
      fill: c.$black;
      min-height: 24px;
      min-width: 24px;
      @include v.transition-opacity;
      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__file-container {
    flex: 1;
    display: flex;
    align-items: center;
    gap: v.$margin-6;
    padding: v.$padding-10 0;
    cursor: pointer;

    &__file {
      fill: c.$gray-medium-dark-2;
      min-height: 24px;
      min-width: 24px;
    }

    span {
      color: c.$gray;
    }
  }
}
