@use "../../../../styles/colors.scss" as c;
@use "../../../../styles/variables.scss" as v;

.img {
  object-fit: cover;
  height: 55px;
  width: 55px;
  border-radius: 50%;
}

.table {
  th:first-child,
  td:first-child {
    width: 0;
    padding-right: 0 !important;

    img {
      margin: 0 auto;
    }
  }
  th:nth-child(8) {
    & > div {
      justify-content: center;
    }
  }

  table {
    thead {
      tr {
        th:nth-child(1) {
          > div {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding: v.$padding-4 v.$padding-5 !important;
        }
      }
    }
  }
}
[class$="ant-table-measure-row"] {
  display: none;
}

.has-account-wrapper {
  display: flex;
  justify-content: center;
}
