@use "../../styles/variables.scss" as v;
@use "../../styles/colors.scss" as c;

.container {
  display: flex;
  flex-direction: row;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  input[type="range"] {
    -webkit-appearance: none;
    margin: v.$margin-3 0;
    width: 100%;
    height: 5px;
    background: c.$light-gray;
    outline: none;
    border-radius: 30px;
    &:disabled {
      cursor: not-allowed;
    }
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 15px;
      height: 15px;
      background: c.$navy-blue;
      border: 0.3px solid c.$navy-blue;
      border-radius: 50%;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 15px;
      height: 15px;
      background: c.$navy-blue;
      border: 0.3px solid c.$navy-blue;
      border-radius: 50%;
      cursor: pointer;
    }

    &::-ms-thumb {
      width: 15px;
      height: 15px;
      background: c.$navy-blue;
      border: 0.3px solid c.$navy-blue;
      border-radius: 50%;
      cursor: pointer;
    }

    &::-ms-track {
      background: c.$light-gray;
      border-radius: 30px;
    }
  }

  .label {
    font-weight: 500;
    font-size: v.$font-size-xs + 2;
    font-weight: normal;
    margin-bottom: 0;
    margin-left: v.$margin-5;
  }
}

.label {
  margin-left: v.$margin-3;
  width: 200px;
}
