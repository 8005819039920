@use "../../../../styles/colors.scss" as c;
@use "../../../../styles/variables.scss" as v;

.card {
  padding: v.$padding-6;
}

.container {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.title {
  display: flex;
  align-items: center;
  gap: v.$margin-3;

  height: fit-content;

  font-weight: 700;
  font-size: v.$font-size-sm + 1;
  color: c.$gray;

  &__icon {
    width: fit-content;
    height: fit-content;

    padding: v.$padding-1 v.$padding-1 0 v.$padding-1;
    border-radius: v.$border-radius-2;

    background-color: c.$red-light-background;

    svg {
      stroke: c.$primary;
      fill: c.$primary;
      stroke-width: 0px;
    }
  }

  &__text {
    text-transform: uppercase;
  }
}

.details {
  display: flex;
  flex-direction: column;
  gap: v.$margin-4;

  font-size: v.$font-size-sm + 1;
  color: c.$gray;

  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: v.$margin-3;
  }
}
