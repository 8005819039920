@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;

.title {
  min-width: 180px;
  flex: 0;
}

.gray-bold {
  font-size: v.$font-size-xl;
  font-weight: 700;
  color: c.$gray-dark-1;
  text-transform: uppercase;
}

.content {
  flex: 1;
}
