@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  & > label {
    font-weight: 700;
    font-size: v.$font-size-md;
    text-transform: uppercase;
    color: c.$gray-dark;
  }
}

.placeholder {
  color: c.$gray-medium-dark;
  text-transform: capitalize;
}

.description {
  color: c.$gray-medium-dark;
  font-size: v.$font-size-sm;
  margin: 0;
}
