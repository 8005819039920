.container {
}

.row {
  display: flex;
  gap: 20px;
  > div {
    width: 100%;
  }
}

.title {
  font-size: 18px;
}
