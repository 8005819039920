@use "../../../../../styles/colors.scss" as c;
@use "../../../../../styles/variables.scss" as v;

.table {
  th:first-child,
  td:first-child {
    width: 0;
    padding-right: 0 !important;

    img {
      margin: 0 auto;
    }
  }
  th:nth-child(8) {
    & > div {
      justify-content: center;
    }
  }

  table {
    thead {
      tr {
        th:nth-child(1) {
          > div {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding: v.$padding-4 v.$padding-5 !important;
        }
      }
    }
  }
}
[class$="ant-table-measure-row"] {
  display: none;
}

.price-total {
  font-weight: 600;
  color: c.$gray-medium-dark-2;
}

.download-invoice {
  display: flex;
  gap: v.$margin-2;

  color: c.$primary;
  text-decoration: underline;
  background-color: transparent;

  svg {
    margin: auto;
    fill: c.$primary;
    transform: scale(1.2);
  }
}

.order-number {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: v.$margin-2;
}

.payment-method {
  display: flex;
  justify-content: center;
}
