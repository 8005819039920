@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;

.tag {
  border-radius: v.$border-radius-3;
  padding: v.$padding-1 v.$padding-5;
  font-size: v.$font-size-xs !important;
  line-height: 1;
  min-width: fit-content;
  display: inline-block;
  margin: v.$margin-1 0;

  &:not(:last-child) {
    margin-right: v.$margin-1;
  }

  svg {
    fill: currentColor;
    width: 24px;
    height: 24px;
  }

  &.isIcon {
    border-radius: v.$border-radius-2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    padding: 0;
  }
}

.link {
  cursor: pointer;
}

@mixin setOutlinedColors($color) {
  border-color: $color;
  color: $color;
}

@mixin setFilledColors($color) {
  background-color: rgba($color, 0.22);
  color: $color;
}

.outlined {
  border: 1px solid;
  background-color: transparent;

  &.green {
    @include setOutlinedColors(c.$dark-green);
  }

  &.orange {
    @include setOutlinedColors(c.$orange-tag);
  }

  &.red {
    @include setOutlinedColors(c.$primary);
  }

  &.purple {
    @include setOutlinedColors(c.$purple-tag);
  }

  &.gray {
    @include setOutlinedColors(c.$gray-dark-1);
  }

  &.blue {
    @include setOutlinedColors(c.$blue);
  }
}

.filled {
  &.green {
    @include setFilledColors(c.$dark-green);
  }

  &.orange {
    @include setFilledColors(c.$orange-tag);
  }

  &.red {
    @include setFilledColors(c.$primary);
  }

  &.purple {
    @include setFilledColors(c.$purple-tag);
  }

  &.gray {
    @include setFilledColors(c.$gray-dark-1);
  }

  &.blue {
    @include setFilledColors(c.$blue);
  }
}
