@use "../../../../styles/colors.scss" as c;
@use "../../../../styles/variables.scss" as v;

.address {
  margin: v.$margin-1 + 1 0;
}

.row-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.container {
  color: c.$gray-medium-dark-2;
}

.custom-card {
  box-shadow: none !important;
  border-radius: v.$border-radius-2 !important;
  color: c.$black-2;
  padding: v.$padding-5 + 1;
}

.name-img {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
  img {
    height: 60px !important;
    width: 60px !important;
    border-radius: 50%;
    object-fit: cover;
  }
}
