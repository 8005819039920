@use "../../styles/variables.scss" as v;
@use "../../styles/colors.scss" as c;

.upload {
  position: relative;
  width: fit-content;
  margin-bottom: v.$margin-4 + 1;
  img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.badge-wrapper {
  border-radius: 50%;
  background: c.$primary;
  position: absolute;
  bottom: 3px;
  right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;

  .badge {
    svg {
      width: 14px;
      height: 14px;
      path {
        fill: c.$white;
      }
    }
  }
}
