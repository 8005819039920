@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;

.container {
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 2000ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
}

.content {
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: c.$gray-medium-dark-3;
  color: c.$white;

  padding: v.$padding-3 v.$padding-7;

  font-size: v.$font-size-sm + 1;

  svg {
    cursor: pointer;
    fill: c.$white;
    height: 25px;
    width: 25px;
  }
}
