@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;

.modal {
  cursor: auto;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 470px;

  border-radius: v.$border-radius-3;
  background-color: c.$white;
}
.popup-container {
  padding: v.$padding-10 v.$padding-7;
  .popup-message {
    text-align: center;
    color: c.$black-2;
    font-size: v.$font-size-md;
    font-weight: bold;
    margin-bottom: v.$padding-9;
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: v.$margin-4;
  }
}
