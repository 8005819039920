@use "../../../styles/colors.scss" as c;
@use "../../../styles/variables.scss" as v;

.container {
  width: v.$sidebar-width;
  background-color: c.$primary-light;

  a:hover {
    opacity: 1;
  }

  .logo {
    text-align: center;
    cursor: pointer;
    align-items: center;
    gap: 10px;
    height: v.$header-height;
    padding: v.$padding-11;
    color: c.$gray-darker;
    img {
      max-height: 64px;
      max-width: 80%;
      margin: auto;
    }
  }

  .list-item:not(:last-child) {
    margin-bottom: v.$margin-2;
  }

  .link {
    padding: v.$padding-6 v.$padding-10;
    color: c.$gray-darker;
    font-size: v.$font-size-sm + 1;
    display: flex;
    align-items: center;
    gap: v.$margin-5;
  }

  .submenu-link {
    padding: v.$padding-3 0;
    padding-left: 2 * v.$padding-11 + 4;
    width: 100%;
    font-size: v.$font-size-sm + 1;

    &:hover {
      color: c.$gray-darker;
    }

    &.active:hover {
      color: c.$white;
    }
  }

  .menu-btn {
    @extend .link;
  }

  .menu {
    border: none !important;

    * {
      transition: none !important;
    }

    i {
      display: none;
    }
    background-color: c.$primary-light;

    [class*="ant-menu-submenu-title"] {
      // @extend .link;
      padding-right: 0 !important;

      & > span {
        margin: 0;
      }

      &:hover {
        color: c.$gray-darker;
      }
    }

    [class*="ant-menu-inline"] {
      background-color: c.$primary-light-3;

      & > li {
        padding: 0 !important;
        margin-top: 0;

        &:not(:last-child) {
          margin-bottom: v.$margin-2;
        }

        & > span {
          display: flex;
        }
      }
    }
  }

  .active {
    background-color: c.$gray-dark-1;
    color: c.$white;
  }

  .icon {
    width: 24px;
    height: 24px;
    fill: currentColor;
  }
}
