@use "./variables.scss" as v;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");
$opensans: "Open Sans", sans-serif;

html,
body {
  font-family: $opensans;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  min-height: calc(100vh - calc(100vh - 100%));
}

#root {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
  @include v.transition-opacity;

  &:hover {
    color: inherit;
    opacity: 0.7;
  }
}

b {
  font-weight: 700;
}

.cc--flex {
  display: flex;
}

.cc--content {
  &-center {
    justify-content: center;
  }
}

.cc-custom-list {
  padding: 0;
  list-style: none;
  margin: 0;
}

.cc--of-cover {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@mixin dropdown-item {
  padding: 0;

  &:not(:last-child) {
    margin-bottom: v.$margin-3;
  }

  &:hover {
    background: inherit;
  }
}

.cc-dropdown-menu {
  border-radius: v.$border-radius-2;
  padding: v.$padding-7;

  .ant-dropdown-menu-item {
    @include dropdown-item;

    &--active {
      @include dropdown-item;
    }
  }
}

.cc--text-ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cc--clamped {
  display: block;
  display: -webkit-box;
  white-space: normal;
  -webkit-box-orient: vertical;

  text-overflow: ellipsis;
  overflow: hidden;

  @for $i from 1 through 10 {
    &.cc--lines-#{$i} {
      -webkit-line-clamp: $i;
    }
  }
}
