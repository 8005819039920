@use "../../../styles/colors.scss" as c;
@use "../../../styles/variables.scss" as v;

.container {
  display: flex;
  flex-direction: column;
  gap: v.$margin-7;
  .card {
    display: flex;
    flex-direction: column;
    gap: v.$margin-5;

    .card-text {
      color: c.$gray-medium-dark-2;
      font-size: v.$font-size-sm + 1;
    }

    .card-name {
      @extend .card-text;
      font-weight: bold;
      text-transform: capitalize;
    }
  }
}

.card-title {
  font-weight: 700;
  font-size: v.$font-size-xl;
  color: c.$black-3;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.dragger {
  background-color: c.$gray-light-8;
  border: 1px dashed c.$gray-dark-1;
  border-radius: v.$border-radius-2 + 2;
  height: 200px;
}

.title {
  font-size: v.$font-size-sm + 1;
  color: c.$gray-medium-dark;
  font-weight: 700;
  margin-bottom: v.$margin-2 !important;
}

.upload-description {
  @extend .title;
  font-weight: 400;
  margin: 0;
}

.upload-icon {
  fill: c.$gray-light-2;
  margin-bottom: v.$margin-3;
}

.submit-btn {
  position: absolute;
  top: 0;
  right: v.$margin-1;
}

.preview-image-container {
  width: 100%;
  height: 100%;
  padding: v.$padding-2;
  .preview-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.upload-container {
  position: relative;
  .remove-image-btn {
    position: absolute;
    right: 20px;
    top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .icon {
      display: inline;
      fill: c.$header-light-gray;
      margin-right: v.$margin-1;
      margin-top: v.$margin-1;
    }
    .btn-text {
      display: inline;
      font-size: v.$font-size-sm + 1;
      color: c.$header-light-gray;
    }
  }
}
