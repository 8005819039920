@use "../../../styles/variables.scss" as v;
@use "../../../styles/colors.scss" as c;

.info-card {
  padding: v.$padding-4 v.$padding-8;
  font-size: v.$font-size-sm + 1;
  font-weight: 700;
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: v.$margin-4 + 1;
  }

  ul {
    width: 100%;
  }
}

.key {
  color: c.$black-2;
}

.value {
  color: c.$gray-medium-dark;
  width: 320px;
}

.edit-btn {
  position: absolute;
  top: 0;
  right: v.$margin-1;
}

.row {
  line-height: v.$font-size-xxxl;

  .key {
    padding: 0 v.$padding-6;
  }
}

.title {
  font-size: v.$font-size-lg;
  margin-bottom: v.$margin-5;
  font-weight: bold;
  text-transform: uppercase;
}

.list-item {
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: v.$margin-3 + 2;
  }
}

.subtitle {
  font-size: v.$font-size-md;
  font-weight: bold;
}

.shipping-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: v.$margin-8;
}

.add-btn {
  padding: 0;
  color: c.$gray-dark-1;
  font-weight: 700;

  &,
  &:hover {
    background-color: transparent;
  }
}
