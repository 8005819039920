@use "../../../../styles/colors.scss" as c;
@use "../../../../styles/variables.scss" as v;

.table {
  table {
    border-spacing: 6px 0px !important;
    width: 100%;

    [class="ant-table-content"] {
      background: c.$white;
    }
    [class$="ant-table-column-has-sorters"] > div {
      justify-content: flex-end !important;
    }

    table {
      background: unset;
      background-color: white;
      border-collapse: collapse;
      tr {
        background: unset;
      }
    }
  }

  thead {
    border-collapse: collapse;
  }

  tbody {
    border-collapse: collapse;
    tr[class*="ant-table-placeholder"] {
      margin-top: v.$margin-4;
    }
  }

  tr {
    table-layout: auto;
    display: table;
    width: 100%;
    padding: 0 !important;
    background: unset;

    td {
      border-bottom: none !important;
    }

    th:first-child,
    td:first-child {
      min-width: 59px;
    }

    th:nth-last-child(4),
    td:nth-last-child(4) {
      width: 15%;
    }

    th:nth-last-child(3),
    td:nth-last-child(3) {
      width: 14%;
    }

    th:nth-last-child(2),
    td:nth-last-child(2) {
      width: 17%;
    }
    th:nth-last-child(5),
    td:nth-last-child(5) {
      width: 22%;
    }
    th:nth-last-child(6),
    td:nth-last-child(6) {
      width: 18%;
    }

    th:last-child,
    td:last-child {
      width: 16%;
    }
  }

  .row {
    margin-top: v.$margin-4;
    box-shadow: v.$shadow-1;
  }

  .expanded-row {
    margin-top: v.$margin-2 * -1;
    background: unset;
    border-spacing: 0;
    overflow: hidden;
    border-radius: 0 0 v.$border-radius-2 v.$border-radius-2;
    box-shadow: v.$shadow-1;

    & > td:first-child {
      padding: 0 !important;
    }
  }
}

.icon {
  transition: all 0.2s ease;
}

.inverted {
  @extend .icon;
  transform: scaleY(-1);
}

.button-container {
  display: inline-block;
  height: 30px;
}

.black-bold {
  font-weight: 700;
  color: c.$black-2;
  width: 200px;
  img {
    min-width: 65px;
    min-height: 65px;
    max-width: 65px;
    max-height: 65px;
    object-fit: contain;
  }
}

.gray-bold {
  font-weight: 700;
  color: c.$gray-medium-dark;
}

.expand-btn {
  margin: 0 auto;
  padding: 0;
  height: 30px;
  display: block;
  background-color: transparent;

  &:hover,
  &:active {
    background-color: c.$white;
  }
}

.tag-container {
  display: flex;
  overflow-y: scroll;
  max-width: 15vw;
}

.max-content {
  display: block;
  width: max-content;
}
.add-icon {
  width: 14px;
  height: 14px;
}
