@use "../../../../styles/colors.scss" as c;
@use "../../../../styles/variables.scss" as v;

.bold {
  font-weight: bold;
}

.no-wrap {
  white-space: nowrap;
}

.table {
  table {
    tbody {
      // first child is hidden measuring row;
      tr {
        // needs more padding on this specific table!
        td {
          padding: v.$padding-5 v.$padding-5 !important;

          // this is the first column with the icon
          &:first-child {
            padding-right: 0 !important;
          }
        }
      }
    }

    thead {
      tr {
        // center payment, status header cols;
        th {
          &:nth-child(5),
          &:nth-child(6) {
            > div {
              justify-content: center;
            }
          }

          // icon column
          &:first-child {
            width: 50px;
          }
        }
      }
    }
  }
}

.order-wrapper {
  display: flex;
  align-items: center;

  svg {
    width: 13px !important;
    height: 17px !important;
  }
}

.order {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: v.$border-radius-2;
  position: relative;
  height: 36px;
  width: 36px;
}

.buyer-wrapper {
  flex: 0;
  width: fit-content;
  img {
    width: 28px !important;
    height: 28px !important;
    margin-right: v.$margin-1 !important;
  }

  span {
    cursor: pointer;
  }
}

.payment,
.status {
  display: flex;
  justify-content: center;
}
