@use "../../styles/variables.scss" as v;
@use "../../styles/colors.scss" as c;

.container {
  margin: 0 v.$lateral-padding;
  @media (max-width: v.$max-breakpoint-sm) {
    margin: 0 v.$lateral-phone-padding;
  }
  @media (max-width: v.$max-breakpoint-md) {
    margin: 0 v.$lateral-tablet-padding;
  }
}

.title-container {
  padding: v.$padding-4 v.$padding-8;
  display: flex;
  align-items: center;
  gap: v.$margin-3;
  font-size: v.$font-size-xxl;
  font-weight: 700;
  text-transform: uppercase;

  &__back-button {
    cursor: pointer;
    @include v.transition-opacity;
    &:hover {
      opacity: 0.7;
    }
  }
}

.card-content {
  display: flex;
  flex-direction: column;
  gap: v.$margin-3;
  font-size: v.$font-size-sm;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: v.$padding-2;

    &__title {
      font-size: v.$font-size-xl;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  button {
    font-size: v.$font-size-sm;
  }

  &__submit {
    margin-top: v.$margin-2;
  }
}
