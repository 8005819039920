@use "../../../styles/variables.scss" as v;
@use "../../../styles/colors.scss" as c;

.btn {
  padding: 0;
  color: c.$gray-dark-1;
  font-weight: 700;

  &:hover {
    background-color: c.$white;
  }
}

.add-btn {
  @extend .btn;
}

.remove-btn {
  @extend .btn;
  position: absolute;
  top: 6px;
  right: 12px;
  height: auto;
}

.type-column {
  flex: 1;
}

.submit-btn {
  position: absolute;
  top: 0;
  right: v.$margin-1;
}

.attribute-item {
  position: relative;
  margin-bottom: v.$margin-6;

  &::after {
    content: "";
    width: 100%;
    height: 1px;
    margin: 0 12px;
    border-bottom: 1px solid c.$header-light-gray;
  }
}

.name-column p {
  max-width: 510px;
}
