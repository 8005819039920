@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;

.container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: v.$margin-5;
  overflow: hidden;
  font-size: v.$font-size-md;
}

.shipping-info-container {
  display: flex;
  flex-direction: column;
  gap: v.$margin-5;

  height: 100vh;
  overflow-y: auto;

  color: c.$gray;

  img {
    height: 60px;
  }
}
