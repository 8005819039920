@use "../../../../styles/variables.scss" as v;
@use "../../../../styles/colors.scss" as c;

.container {
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media (max-width: v.$max-breakpoint-sm) {
    padding: v.$padding-7;
  }
}

.card {
  margin-top: v.$margin-7;
}

.row {
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 25px;
  width: 100%;
  padding-bottom: v.$padding-7;

  @media (max-width: v.$max-breakpoint-xl) {
    display: flex;
    flex-direction: column;
    grid-gap: 0px;
  }
}
