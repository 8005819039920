@use "../../../../styles/colors.scss" as c;
@use "../../../../styles/variables.scss" as v;

.container {
  display: flex;
  flex-direction: column;

  padding: v.$padding-9;
}

.progress-bar {
  height: 3px;
  background-color: c.$primary;
}

.footer {
  display: flex;
  margin-left: auto;
  margin-top: v.$margin-6;
  gap: v.$padding-5;
}

.title {
  font-weight: 600;
  font-size: v.$font-size-lg + 1;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: v.$margin-4;

  &__buttons {
    display: flex;
    gap: v.$margin-4;
  }
}
