@use "../../../../styles/colors.scss" as c;
@use "../../../../styles/variables.scss" as v;

.layout {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.header {
  display: flex;
  margin-bottom: v.$margin-4;
  gap: v.$margin-3;
  align-items: center;
  width: 100%;

  .back-btn {
    padding: 0;
    color: c.$gray-dark-1;
    height: 24px;

    &:hover {
      background-color: c.$white;
    }
  }
}

.title {
  font-size: v.$font-size-xxl;
  color: c.$black-2;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0;
}

.submit-btn {
  border: 1px red solid;
  position: absolute;
  top: 0;
  right: 0;
}

.section {
  margin-bottom: v.$margin-9;
}

.field-label-description {
  font-weight: 400;
  display: inline-block;
  text-transform: lowercase;

  &::first-letter {
    text-transform: capitalize;
  }
}

.link {
  font-weight: 700;
  color: c.$header-light-gray;
  text-decoration: underline;
}

.attribute-item {
  position: relative;
  margin-bottom: v.$margin-6;

  &::after {
    content: "";
    width: 100%;
    height: 1px;
    margin: 0 12px;
    border-bottom: 1px solid c.$header-light-gray;
  }
}

.btn {
  padding: 0;
  color: c.$gray-dark-1;
  font-weight: 700;

  &:hover {
    background-color: c.$white;
  }
}

.add-btn {
  @extend .btn;
}

.remove-btn {
  @extend .btn;
  position: absolute;
  top: 6px;
  right: 12px;
  height: auto;
}

.type-column {
  flex: 1;
}

.name-column p {
  max-width: 510px;
}
