.container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.color {
  width: 36px;
  height: 14px;
  border-radius: 2px;
}

.swatch {
  margin-right: 10px;
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  display: inline-block;
  border: 1px solid black;
  cursor: pointer;
}
.popover {
  position: absolute;
  z-index: 2;
  top: -30px;
  left: 38px;
  &:before {
    content: "";
    background: #fff;
    position: absolute;
    top: 30px;
    left: -6px;
    width: 13px;
    height: 13px;
    transform: rotate(45deg);
    z-index: 0;
  }
}
.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.color-picker {
  position: relative;
  border-radius: 7.15026px !important;
  background: #f3f6f9 !important;
  z-index: 9999999;
  color: red;
  [class$="saturation-white"] > div > div {
    width: 10px !important;
    height: 10px !important;
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 5px,
      rgba(0, 0, 0, 0.3) 0px 0px 1px 0px inset,
      rgba(0, 0, 0, 0.4) 0px 0px 1px 0px !important;
    border: 0.595855px solid rgba(0, 0, 0, 0.1);
  }
  [class$="hue-horizontal"] {
    border-radius: 10px !important;
    height: 7.15px;
    padding: 10px;
    & > div > div {
      position: relative !important;
      width: 8px !important;
      height: 8px !important;
      background-color: transparent !important;
      top: -4px;

      border-radius: 50% !important;
      border: 5px solid white;
      outline: 0.5px solid rgba(0, 0, 0, 0.1);

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 10%;
        left: 10%;
        width: 80%;
        height: 80%;
        border-radius: 50%;
        background-color: transparent;
      }
    }
  }
  & > div:last-child {
    display: none !important;
  }
  & > div:nth-child(3) {
    & > div > div {
      display: flex !important;
      flex-direction: column;
    }
    input {
      font-size: 16px;
      order: 2;
      margin-bottom: 14px;
      color: #111111;
      border-radius: 5px;
      border: 0.595855px solid #e5e8e8;
      &:focus {
        border: 0.595855px solid #e5e8e8 !important;
      }
      &:focus-visible {
        outline: none;
      }
    }
    label {
      order: 1;
      padding-bottom: 2px !important;
      font-weight: 700;
      font-size: 12px;
      color: #111111;
      text-align: start !important;
      margin-left: 4px;
    }
  }
  & > div:nth-child(2) {
    & > div:last-child {
      display: none !important;
    }
    & > div:first-child > div:last-child {
      display: none !important;
    }
    & > div:first-child > div:first-child {
      overflow: visible !important;
    }
  }
}
