@use "../../styles/variables.scss" as v;
@use "../../styles/colors.scss" as c;

.table {
  margin: 0 -5px;
  max-width: unset !important;

  table {
    padding: 0 5px;

    tbody {
      tr {
        box-shadow: v.$shadow-1;
      }
    }
  }
}

.title {
  font-size: v.$font-size-lg;
  font-weight: bold;
  text-transform: uppercase;
}

.absolute-btn {
  position: absolute;
  top: 0;
  right: v.$margin-1;
}

.text {
  font-size: v.$font-size-sm + 1;
  font-weight: 700;
}

.header {
  @extend .text;
  color: c.$black-2;
}

.row {
  @extend .text;
  color: c.$gray-medium-dark;
}
