@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;

.upload-card-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: v.$margin-4;
}

.dragger {
  background-color: c.$gray-light-8;
  border: 1px dashed c.$gray-dark-1;
  border-radius: v.$border-radius-2 + 2;
  height: 200px;
}

.title {
  font-size: v.$font-size-sm + 1;
  color: c.$gray-medium-dark;
  font-weight: 700;
  margin-bottom: v.$margin-2;
}

.upload-card {
  min-width: 380px;
  flex: 0;
}

.gallery-card {
  flex: 1;
}

.upload-description {
  @extend .title;
  font-weight: 400;
  margin: 0;
}

.card-description {
  color: c.$header-light-gray;
}

.upload-icon {
  fill: c.$gray-light-2;
  margin-bottom: v.$margin-3;
}

.uploading-state {
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  font-weight: 700;
  font-size: v.$font-size-xs;
  width: 100%;
  height: 100%;
  padding: v.$padding-2 v.$padding-1;
  background-color: rgba(c.$gray-light-6, 0.6);
}

.img-container {
  overflow: hidden;
  border-radius: v.$border-radius-2 + 2;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    .remove-btn {
      display: flex;
    }
  }
}

.item {
  @extend .img-container;
  position: relative;
  width: 90px;
  height: 90px;
}

.selected {
  outline: c.$primary solid 1px;
}

.main-image {
  @extend .img-container;
  @extend .selected;
  width: 210px;
  height: 150px;
}

.upload-table-header {
  display: grid;
  grid-template-columns: 1.5fr 7.5fr 1fr 1fr;
  gap: 15px;
  div:first-of-type {
    min-width: 90px;
  }
}

.product-image-row {
  display: grid;
  grid-template-columns: 1.5fr 7.5fr 1fr 1fr;
  gap: 15px;
  margin-top: v.$margin-4;
}

.description {
  resize: none;
  border-radius: v.$border-radius-1 + 2;
}

.thumbnail {
  margin: auto;
  cursor: pointer;
  accent-color: red;
}

.delete-icon {
  height: 100%;
  margin: auto;
  fill: c.$gray-medium-dark;
  cursor: pointer;
}

textarea {
  padding: v.$padding-2;
}

textarea:placeholder-shown {
  font-style: italic;
}
