@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;

.container {
  color: c.$gray-medium-dark-2;
}

.name-img {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
  img {
    height: 60px !important;
    width: 60px !important;
    border-radius: 50%;
    object-fit: cover;
  }
}

.custom-card {
  box-shadow: none !important;
  border-radius: v.$border-radius-2 !important;
  color: c.$black-3;
  padding: v.$padding-5 + 1;
}
