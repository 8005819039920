@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;

.section-container {
  width: 100%;

  .title {
    font-size: v.$font-size-sm;
    color: c.$header-light-gray;
    margin-bottom: v.$margin-2;
  }

  .settings-container {
    display: flex;
    flex-direction: column;
    row-gap: v.$margin-4;

    .setting-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: v.$padding-3;

      .content {
        font-size: v.$font-size-sm;
        color: c.$black;
        margin: 0;
      }
    }
  }
}
