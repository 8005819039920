@use "../../../../styles/variables.scss" as v;

.container {
  display: flex;
  flex-direction: column;
  gap: v.$margin-7;

  margin: 0 auto;
  max-width: 1000px;
  padding-left: 100px;
}
