@use "../../styles/variables.scss" as v;

@import "../../styles/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-weight: 700;
    font-size: v.$font-size-md;
    text-transform: uppercase;
    color: $gray-dark;
  }

  input {
    flex: 1;
    width: 100%;
    border: none;
    outline: none;
    border-radius: v.$border-radius-2;
    font-size: v.$font-size-sm;
    min-height: 38px;
    padding: v.$padding-1 + 1 v.$padding-5;
    @include v.transition-colors;
    position: relative;

    &[type="number"] {
      -moz-appearance: textfield;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &::placeholder {
      color: $header-light-gray;
      text-transform: capitalize;
    }

    &:hover,
    &:focus,
    &:active,
    &:focus-visible {
      border: none !important;
      outline: none !important;
    }
  }

  &:not(:last-child) {
    margin-bottom: v.$margin-4;
  }
}

.error {
  visibility: hidden;
  color: $red;
  margin-top: v.$margin-1;
  margin-left: v.$margin-1;
  font-size: v.$font-size-sm;

  &--visible {
    visibility: visible;
  }
}

.description {
  color: $gray-medium-dark;
  font-size: v.$font-size-sm;
  margin: 0;
}

.input-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: v.$margin-1 + 1;
  border: 1px solid $gray-dark;
  border-radius: v.$border-radius-2;
  color: $gray-darker;
  background-color: transparent;
  &:focus-within {
    border: 1px solid $black;
    outline: none !important;
  }
}

.start-component {
}

.end-component {
}
