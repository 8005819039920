@use "../../../../../styles/variables.scss" as v;
@use "../../../../../styles/colors.scss" as c;

.container {
  height: calc(100% - v.$header-height);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: c.$frosty-blue;
  overflow-y: auto;
  position: relative;
}

.fab {
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 1;

  &:hover {
    opacity: 1;
  }

  &__icon {
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
  }
}

.canvas {
  background: c.$light-green;
  max-height: 90%;
  max-width: calc(100% - 2 * v.$lateral-padding);
  border-radius: v.$border-radius-4;
  background: c.$frosty-blue;
  align-self: flex-start;
  & > div {
    height: 100vh !important;
  }
}

.editContainer {
  display: flex;
  flex-direction: row;
  min-width: 420px;
  min-height: 100%;
  background-color: c.$light-blue-gray;
  min-height: 100vh;
}

.menu {
  display: flex;
  flex-direction: column;
  background: c.$navy-blue;
  padding-top: v.$padding-1 + 1;
  &-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: v.$margin-2;
    &:last-child {
      border-top: 1px solid c.$gray-light-5;
      border-bottom: 1px solid c.$gray-light-5;
      padding-top: v.$padding-2;
      padding-bottom: v.$padding-2;
      margin-right: v.$margin-1 - 1;
      margin-left: 4px;
      button {
        margin-right: -4px;
      }
    }
    button {
      min-width: 102px;
      margin-left: v.$margin-1 - 1;
      height: 102px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      font-weight: normal;
      font-size: v.$font-size-xs + 2;
      background-color: c.$navy-blue;
    }
    &-svg {
      path {
        fill: c.$navy-blue !important;
      }
    }
    &-text {
      color: c.$navy-blue !important;
    }
    &-button {
      &:hover {
        background-color: c.$navy-blue !important;
      }
    }
    &-background {
      background-color: c.$light-blue-gray !important;
      &:hover {
        background-color: c.$light-blue-gray !important;
      }
    }
  }
}

.icon-container {
  display: flex;
  justify-content: end;
  gap: 5px;
  margin-top: v.$margin-1 + 1;
  text-transform: capitalize;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  text-transform: capitalize;
}

.decals-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.dropdown {
  display: block;
  & > div[aria-expanded="false"] {
    svg {
      transform: rotate(180deg);
    }
  }
  & > div[aria-expanded="true"] {
    svg {
      transform: rotate(0deg);
    }
    .dropdown-input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    + .dropdown-container {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  &-container {
    position: relative;
    box-shadow: none;
    background-color: c.$grayish-blue;
    border-radius: v.$border-radius-2;
    padding: v.$padding-7;
    width: auto;
    & > div {
      background-color: transparent;
    }
    &::before {
      display: none;
    }
  }

  &-button {
    border-bottom: 0 !important;
    background: c.$grayish-blue !important;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    border-radius: v.$border-radius-2;
    color: inherit;
    border: none;
    padding: 0.5em;
    &:hover {
      background-color: c.$grayish-blue !important;
    }
  }
  &-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f3f6f9 !important;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;

    & input,
    & .upload-label {
      margin-left: 0.25em;
    }

    &:hover {
      cursor: pointer;
    }
    svg {
      margin: v.$margin-5;
      &:hover {
        cursor: pointer;
      }
    }
  }
  &-switch-box {
    display: flex;
    height: 46px !important;
    width: 42px;
    flex-direction: column;
    gap: 10px;
    background-color: c.$navy-blue;
    border-radius: 0 v.$border-radius-2 v.$border-radius-2 0;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 14px;
    right: -62px;
    svg {
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
    & > div:first-child {
      margin-top: 16px;
    }
    & > div:last-child {
      margin-bottom: v.$margin-5;
    }
  }

  &__file-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 16ch;
    flex-shrink: 1;
    font-size: v.$font-size-sm - 2;
  }
}
.middle {
  &-bar {
    width: 100%;
    margin: v.$margin-5;
  }
  &-btn {
    height: 44px;
    margin-bottom: v.$margin-1 + 2;
    text-transform: capitalize;
  }
  &-btn-container {
    display: flex;
    align-items: center;
    gap: 20px;
    text-transform: capitalize;
    svg {
      stroke: white;
      fill: white;
      width: 14px;
    }
  }
}

.file {
  &::-webkit-file-upload-button {
    display: none;
  }
}

.upload-label {
  display: inline-block;
  width: 48px;
  height: 48px;
}

.upload {
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: contain;
  &:hover {
    cursor: pointer;
  }
}

.node-name {
  margin-top: 1em;
  text-align: center;
  font-weight: 700;
  opacity: 0.5;
}

.actions {
  display: flex;
  // flex-direction: column;
  gap: 1em;
  margin-top: v.$margin-5;
}

.confirm-delete-modal {
  background-color: c.$white;
  padding: 0.5rem;
  border-radius: v.$border-radius-1;

  &__message {
    margin-bottom: 1.5em;
    text-align: center;
  }

  &__actions {
    display: flex;
    gap: 1em;
  }
}
