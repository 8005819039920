@use "../../styles/variables.scss" as v;
@use "../../styles/colors.scss" as c;

.container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 38px;
  border: 0px;
  border-radius: v.$border-radius-2 + 2;
  padding: 0 v.$padding-7;
  font-weight: 400;
}

.icon {
  display: flex;
  align-items: center;

  height: 15px;

  svg {
    fill: currentColor;
  }
}

.primary {
  @extend .container;
  background-color: c.$primary;
  color: c.$white;
}

.primary:hover {
  background-color: c.$primary-dark;
}

.primary:active {
  background-color: c.$primary;
}

.outlined {
  @extend .container;
  border: 1.5px solid c.$gray-dark;
  color: c.$gray-dark;
  background-color: c.$white;
}

.outlined:hover {
  background-color: c.$gray-alternate;
}

.outlined:active {
  background-color: c.$white;
}

.primary-outlined {
  @extend .container;
  border: 1.5px solid c.$primary;
  color: c.$primary;
  background-color: c.$white;
}

.primary-outlined:hover {
  background-color: c.$gray-alternate;
}

.primary-outlined:active {
  background-color: c.$white;
}

.black {
  @extend .container;
  color: c.$white;
  background-color: c.$black;
}

.black:hover {
  background-color: c.$gray-darker;
}

.black:active {
  background-color: c.$black;
}

.white {
  @extend .container;
  color: c.$primary;
  background-color: c.$white;
}

.white:hover {
  background-color: c.$gray-light-1;
}

.white:active {
  background-color: c.$white;
}

.secondary {
  @extend .container;
  background-color: c.$white;
  border: 1px solid c.$gray-light-9;
  color: c.$gray-dark-1;
  svg {
    fill: c.$black;
  }
}

.secondary:hover {
  background-color: c.$gray-light-1;
}

.secondary:active {
  background-color: c.$white;
}

.disabled {
  &,
  &:hover,
  &:active {
    cursor: not-allowed;
    background-color: c.$gray-light-3;
  }
}

.loading {
  margin-top: 0 !important;
  color: white !important;
}
