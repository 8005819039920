@use "../../styles/variables.scss" as v;
@use "../../styles/colors.scss" as c;

.container {
  position: relative;

  padding: v.$padding-11;
  box-shadow: v.$shadow-1;
  border-radius: v.$border-radius-2 + 2;
  background-color: c.$white;
}

.title {
  font-size: v.$font-size-lg;
  margin-bottom: v.$margin-5;
  font-weight: bold;
}
.title-row {
  display: flex;
  gap: 10px;
  & > svg {
    background-color: c.$gray-light-10;
    border-radius: v.$border-radius-2;
    padding: v.$padding-1;
  }
}
