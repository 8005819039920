@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;

.container {
}

.ant-input-search {
  input {
    &[class*="ant-input"] {
      &:hover,
      &:focus {
        border: 1px solid c.$gray;
        box-shadow: none;
      }
    }

    height: 38px;
    padding-right: v.$padding-14; // absolutely positioned button offset
    border-radius: v.$border-radius-2 !important; //very hard to overwrite otherwise
    border: 1px solid c.$gray;
  }

  [class*="ant-input-search-button"] {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
    border-left: none;
    border-color: transparent;
    border-radius: v.$border-radius-2 !important; //very hard to overwrite otherwise
    background: transparent;
    box-shadow: none;
    height: 38px;

    span {
      display: flex;
      left: 0px !important;
      span {
        left: 0px !important;
      }

      svg {
        fill: c.$black;
        font-size: v.$font-size-lg;
      }
    }
  }
}
