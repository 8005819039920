@use "../../../../styles/colors.scss" as c;
@use "../../../../styles/variables.scss" as v;

.gray-bold {
  font-size: v.$font-size-xl;
  font-weight: 700;
  color: c.$gray-dark-1;
  text-transform: uppercase;
}

.title {
  min-width: 180px;
  flex: 0;
}

.content {
  flex: 1;
  min-width: 0;
}

.submit-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.section {
  margin-bottom: v.$margin-9;
}

.warning-banner {
  @extend .section;
  padding: v.$padding-6 v.$padding-9;
  box-shadow: none;
  background-color: rgba(c.$primary, 0.25);
  color: c.$primary;
  font-weight: 700;
  display: flex;
  gap: v.$margin-3 + 2;
  align-items: center;
  svg {
    fill: currentColor;
  }
}

.outside-title {
  margin-bottom: v.$margin-4;
  line-height: 1;
}

.hidden {
  height: 0px;
  position: absolute;
  z-index: -3;
  opacity: 0;
}

.comment {
  font-weight: 400;
  display: inline-block;
  text-transform: lowercase;

  &::first-letter {
    text-transform: capitalize;
  }
}

.link {
  font-weight: 700;
  color: c.$header-light-gray;
  text-decoration: underline;
}

.attribute-item {
  position: relative;
  margin-bottom: v.$margin-6;

  &::after {
    content: "";
    width: 100%;
    height: 1px;
    margin: 0 12px;
    border-bottom: 1px solid c.$header-light-gray;
  }
}

.btn {
  padding: 0;
  color: c.$gray-dark-1;
  font-weight: 700;

  &:hover {
    background-color: c.$white;
  }
}

.add-btn {
  @extend .btn;
}

.remove-btn {
  @extend .btn;
  position: absolute;
  top: 6px;
  right: 12px;
  height: auto;
}

.type-column {
  flex: 1;
}

.name-column p {
  max-width: 510px;
}

.published-column {
  position: absolute;
  top: -6px;
  display: flex;
  align-items: center;
  gap: v.$margin-4;

  .side-label {
    font-weight: 700;
    font-size: v.$font-size-sm;
    text-transform: uppercase;
    color: c.$gray-dark;
    min-width: 200px;
  }

  .select {
    min-width: 100px;
  }
}

.list-item-input-wrapper {
  margin-top: v.$margin-5 !important;
}

.base-url {
  padding-left: v.$padding-5;
  color: c.$gray-light-3;
}
