@use "../../../../../styles/colors.scss" as c;
@use "../../../../../styles/variables.scss" as v;

.container {
  height: 100%;

  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1150px;

  .title {
    font-size: v.$font-size-xxl;
    color: c.$black-2;
    font-weight: bold;
    text-transform: uppercase;
  }

  .cards-wrapper {
    display: flex;
    margin-left: v.$margin-1 * -1;
    padding-left: v.$padding-1;
    gap: v.$margin-5;
    flex: 1;
    overflow: auto;
  }

  .control-panel {
    width: 300px;
    height: fit-content;
    padding: v.$padding-5 0;
  }

  .control-btn {
    height: 56px;
    padding-left: v.$padding-16 - 2;
    font-size: v.$font-size-sm + 1;
    color: c.$black-3;
    border-radius: 0;
    justify-content: flex-start;
    position: relative;

    &.active {
      color: c.$primary;
      font-weight: 700;

      & svg {
        fill: c.$primary;
      }

      &::before {
        content: "";
        height: 100%;
        border-radius: 0 v.$border-radius-1 v.$border-radius-1 0;
        border-right: 3px solid c.$primary;
        width: 3px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .title {
    display: flex;
    padding-right: v.$padding-1;
    width: 100%;
    justify-content: space-between;
  }

  .component-wrapper {
    flex: 1;
    padding: v.$padding-1;
    padding-top: 0;
    overflow: auto;
    padding-bottom: v.$padding-14 * 2;

    & > div {
      position: unset;
    }
  }
}

.header {
  align-items: center;
  &-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
[class$="ant-page-header-ghost"] {
  padding: 0;
}
[class$="ant-page-header-back"] {
  line-height: 0 !important;
}
