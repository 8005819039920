@use "../../../../styles/colors.scss" as c;
@use "../../../../styles/variables.scss" as v;

.container {
  display: flex;
  flex-direction: column;
  gap: v.$margin-7;
}

.table-order {
  [class$="ant-table-content"] {
    background: unset;
  }

  [class$="ant-table-title"] {
    padding: 0 !important;
  }

  table {
    border-collapse: separate !important;
    background-color: #ffffff;

    div {
      font-size: v.$font-size-sm + 1 !important;
    }

    thead {
      tr {
        box-shadow: 0px 2px 6px 1px c.$gray-light-7 !important;
        background: unset !important;

        [class$="ant-table-selection-column"] {
          span {
            visibility: hidden !important;
          }
        }
      }
    }

    tbody {
      tr {
        border-radius: v.$border-radius-2;
        padding: 0 !important;
        margin: 0 !important;
        box-shadow: 0px 2px 6px 1px c.$gray-light-7 !important;
        background: unset !important;

        td {
          //To fix checkbox issue
          &:first-child {
            span {
              border-spacing: initial;
            }
          }
        }
      }
    }
  }
}

.client-name-pic {
  display: flex;
  align-items: center;

  img {
    width: 35px !important;
    height: 35px !important;
    border-radius: 50%;
    object-fit: contain;
  }
}

.product-variant-container {
  display: flex;
  align-items: center;

  img {
    min-height: 60px;
    min-width: 60px;
    max-width: 60px;
    max-height: 60px;
    object-fit: contain;
  }
}

.order-number {
  display: flex;
  align-items: center;
  gap: v.$margin-1;
}

.order-header {
  font-size: v.$font-size-md;
  color: c.$gray-medium-dark-2;
  font-weight: 600;
}

.quantity {
  display: flex;
  gap: v.$margin-1;
  align-items: center;
  font-size: v.$font-size-sm + 1 !important;
  > div {
    min-width: 75px;
  }
}

.price {
  display: flex;
  gap: v.$margin-2;
  &__currency {
    font-size: v.$font-size-xs;
    font-weight: 300;
  }
  &__value {
    font-size: v.$font-size-sm + 1 !important;
    font-weight: 600;
    color: c.$gray-medium-dark-2;
  }
}

.table-product {
  [class$="ant-table-content"] {
    height: 350px;
    overflow: auto;
    background: unset;
    table {
      background-color: white !important;
    }
  }

  [class$="ant-table-title"] {
    padding: 0 !important;
  }

  table {
    font-size: v.$font-size-sm + 1 !important;
    border-collapse: separate !important;
    background: unset;

    thead {
      tr {
        box-shadow: 0px 2px 6px 1px c.$gray-light-7 !important;
        background: unset !important;

        [class$="ant-table-selection-column"] {
          span {
            visibility: hidden !important;
          }
        }
      }
    }

    tbody {
      tr {
        border-radius: v.$border-radius-2;
        padding: 0 !important;
        margin: 0 !important;
        box-shadow: 0px 2px 6px 1px c.$gray-light-7 !important;
        background: unset !important;

        td {
          //To fix checkbox issue
          &:first-child {
            span {
              border-spacing: initial;
            }
          }
        }
      }
    }
  }
}

.product-variant {
  &__name {
    font-size: 15px;
  }
  &__attributes {
    font-size: v.$font-size-xs;
    max-width: 300px;
  }
}
