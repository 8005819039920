@use "../../../../styles/variables.scss" as v;
@use "../../../../styles/colors.scss" as c;

.table {
  th:first-child,
  td:first-child {
    width: 0;
    padding-right: 0 !important;

    img {
      margin: 0 auto;
    }
  }
  th:nth-child(8) {
    & > div {
      justify-content: center;
    }
  }

  table {
    thead {
      tr {
        th:nth-child(1) {
          > div {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding: v.$padding-4 v.$padding-5 !important;
        }
      }
    }
  }
}
[class$="ant-table-measure-row"] {
  display: none;
}

.clickable {
  cursor: pointer;
}

.bold {
  font-weight: bold;
  color: c.$gray-medium-dark;
}

.no-wrap {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.buyer-wrapper {
  img {
    border-radius: 50%;
    width: 28px !important;
    height: 28px !important;
    object-fit: cover;
    margin-right: v.$margin-1 !important;
  }
}

.invoice-wrapper {
  display: flex;
  align-items: center;

  span {
    text-transform: uppercase;

    &:not(:first-child) {
      &:last-child {
        margin-left: v.$margin-3;
      }
    }
  }
}

.tag {
  &:not(:last-child) {
    margin-right: v.$margin-2;
  }
}

.order {
  text-decoration: underline;
  &::before {
    content: "#";
  }
}

.payments {
  display: flex;
  align-items: center;
  justify-content: center;
}

.price-total {
  font-weight: 600;
}
