@use "../../../styles/variables.scss" as v;
@use "../../../styles/colors.scss" as c;

.label {
  color: c.$gray-medium-dark-2;
  font-weight: 700;
}

.progress {
  margin: v.$margin-2 0;
}

.progress-text {
  font-size: v.$font-size-xs;
}

.link {
  color: c.$primary;
  font-weight: 500;

  &:hover {
    color: c.$primary;
  }
}

.submit {
  margin-top: v.$margin-4;
}

.download-box {
  border-radius: v.$border-radius-1;
  background-color: c.$gray-alternate;
  padding: v.$padding-4;
  margin: v.$margin-3 0;

  &__label {
    color: c.$gray-medium-dark-2;
    margin: v.$margin-2;
    margin-top: 0;
    font-weight: 700;
    width: fit-content;
    cursor: pointer;
    @include v.transition-opacity;
    &:hover {
      opacity: 0.7;
    }
  }

  &__file {
    border: 1px solid c.$gray-medium-dark-2;
    border-radius: v.$border-radius-2;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: v.$margin-4;
    padding: v.$padding-5;
    margin: v.$margin-1;
    margin-top: v.$margin-3;
    cursor: pointer;
    @include v.transition-opacity;

    &:hover {
      opacity: 0.7;
    }

    &__icon {
      fill: c.$gray-medium-dark-2;
      min-height: 24px;
      min-width: 24px;
    }

    &__name {
      color: c.$gray;
    }
  }
}
