@use "../../../../styles/variables.scss" as v;
@use "../../../../styles/colors.scss" as c;

.title {
  font-size: v.$font-size-lg;
  margin-bottom: v.$margin-5;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.table {
  margin: 0 -5px;
  max-width: unset !important;

  table {
    padding: 0 5px;
    tbody {
      tr {
        box-shadow: v.$shadow-1;
      }
    }
  }
}

.name {
  color: c.$black-2;
  font: normal normal normal 16px/14px Open Sans;
}

.price {
  color: c.$gray-medium-dark-2;
  font: normal normal bold 16px/16px Open Sans;
}

.description {
  font: normal normal normal 14px/18px Open Sans;
  color: c.$gray-medium-dark-2;
}

.table-header {
  font: normal normal bold 16px/16px Open Sans;
  padding-top: 5px;
  padding-bottom: 5px;
}

.create-btn {
  position: absolute;
  top: 0;
  right: v.$margin-1;
}
