@use "../../../styles/variables.scss" as v;
@use "../../../styles/colors.scss" as c;

.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: v.$padding-9 v.$padding-10 + 2;
  width: 100% !important;
  margin-bottom: 30px !important;
}

.title {
  color: c.$black-2;
  font-size: v.$font-size-xl;
  margin-bottom: 30px;
}

.img {
  max-width: 100px;
  max-height: 100px;
  min-width: 100px;
  min-height: 100px;
  object-fit: contain;
}

.value {
  color: c.$black-2;
  font-size: v.$font-size-xxxl + 4;
  font-weight: bold;
}

.productrow {
  display: flex;
  flex-direction: row;
  font-size: v.$font-size-sm + 1;
  height: fit-content;
  margin-bottom: 20px;
}

.atrrow {
  color: c.$gray-medium-dark-2;
  font-size: v.$font-size-xs + 2;
  margin-top: -15px;
  width: 70%;
}

.nameattr {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 70%;
}

.price {
  color: c.$green-1;
  position: absolute;
  right: 3%;
  margin-top: 2%;
}

.prdname {
  width: 70%;
  font-size: v.$font-size-sm + 1;
  margin-top: -3px;
}
