@use "../../../styles/variables.scss" as v;
@use "../../../styles/colors.scss" as c;

.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: v.$padding-9 v.$padding-10 + 2;
}

.title {
  color: c.$black-2;
  font-size: v.$font-size-xl;
}

.value {
  color: c.$black-2;
  font-size: v.$font-size-xxxl + 4;
  font-weight: bold;
}

.img {
  border-radius: 25px;
  width: 50px;
  height: 50px;
}

.custrow {
  display: flex;
  margin-bottom: 21px;
  text-align: start;

  & p {
    margin-top: 10px;
  }

  .avatar-container {
    width: 42px;
    height: 42px;
    border-radius: 50%;
  }

  .custname {
    text-align: left;
    margin-left: 24px;
    width: 25%;
  }

  .custno {
    text-align: center;
    width: 18.75%;
  }

  .custprice {
    text-align: center;
    width: 18.75%;
  }

  .custpayment {
    text-align: center;
    width: 18.75%;
  }

  .custdate {
    text-align: center;
    width: 18.75%;
  }
}

.indexrow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
  font-size: v.$font-size-xs + 2;
  color: c.$black-2;

  .indexname {
    text-align: left;
    width: 28%;
  }

  .indexno {
    text-align: center;
    margin-left: 24px;
    width: 17.5%;
  }

  .indexamount {
    text-align: center;
    width: 17.5%;
  }

  .indexpayment {
    text-align: center;
    width: 17.5%;
  }

  .indexdate {
    text-align: center;
    width: 17.5%;
  }
}
