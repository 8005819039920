.attributeValues {
  display: flex;
  flex-direction: row;

  button {
    margin-right: 5px;
  }
}

.buttonClick {
  width: fit-content !important;
  border-radius: 5px;
  background-color: transparent !important;
  color: black !important;
  border: 1px solid gray;

  :hover {
    transition: 0.2s !important;
    background-color: rgb(136, 136, 136) !important;
  }
}

.notFound {
  cursor: default !important;
  background: linear-gradient(
    to top left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 0.8px),
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) calc(50% + 0.8px),
    rgba(0, 0, 0, 0) 100%
  );
}

.currentValue {
  background-color: black !important;
  color: white !important;
  border-color: black !important;
}
