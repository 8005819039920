$blue: #007bff;
$primary: #204069;
$primary-dark: #000000;
$primary-light: #edf1f4;
$primary-light-2: #8093a1;
$primary-light-3: #fafbff;
$secondary: #e1251b;
$secondary-dark: #000000;
$light-gray: #b5b5b5;
$navy-blue: #002554;
$grayish-blue: #f3f6f9;

$purple: #6f42c1;
$light-green: #b1d7b75f;
$light-blue-gray: #d6dee9;

$pink: #e83e8c;
$pale-rose: #ffeceb;
$red: #dc3545;
$red-4: #ec5453;
$red-5: #9f000a;
$red-6: #d22b29;
$orange: #fd7e14;
$orange-tag: #d08100;
$purple-tag: #720486;
$yellow: #ffc107;
$yellow-1: #ffab00;
$yellow-2: #feb53e;
$yellow-3: #d08100;
$green: #28a745;
$green-1: #28c66f;
$dark-green: #04863f;
$teal: #20c997;
$cyan: #17a2b8;
$aqua: #63e2f1;
$white: #fff;
$coral-red: #fc4242;
$red-light-background: #ffecec;
$frosty-blue: #edeef4;

$gray-background: #fff;
$black: #000;
$black-1: #0000001a;
$black-2: #1e1e1e;
$black-3: #393939;
$white: #fff;
$gray: #6c757d;
$gray-2: #9d9d9d;
$gray-subdued: #aab0b6;
$gray-medium-dark: #aab0b6;
$gray-alternate: #f6f6f6;
$gray-light: #f4f4f4;
$gray-light-1: #e7e7e7;
$gray-light-2: #cfcfcf;
$gray-light-3: #bababa;
$gray-light-4: #eaeaea;
$gray-light-5: #fefefe;
$gray-light-6: #fafafa;
$gray-light-7: #00000029;
$gray-light-8: #efefef;
$gray-light-9: #f3f3f3;
$gray-light-10: #fae9e9;
$gray-light-11: #c5c5c5;
$gray-light-12: #fcfcfc;

$gray-medium-dark: #666666;
$gray-medium-dark-2: #656565;
$gray-medium-dark-3: #706c6c;

$gray-dark: #343a40;
$gray-dark-1: #393939;
$gray-darker: #212121;

$faded-red: #d84745;
$header-dark-gray: #333333;
$header-light-gray: #6c6c6c;
$blue: #130aff;
