@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;

.container {
  width: 100%;
  height: 6px;
  border-radius: v.$border-radius-1;
  background-color: c.$gray-light-4;
}

.bar {
  height: 6px;
  border-radius: v.$border-radius-1;
  background-color: c.$primary;
  transition: 200ms ease-in-out;
  transition-property: width;
}
