@use "../../../styles/colors.scss" as c;
@use "../../../styles/variables.scss" as v;

.container {
  max-width: 1150px;
  display: flex;
  flex-direction: column;
  position: relative;

  .title {
    font-size: v.$font-size-xxl;
    color: c.$black-2;
    font-weight: bold;
    text-transform: uppercase;
  }

  .cards-wrapper {
    display: flex;
    margin-left: v.$margin-1 * -1;
    padding-left: v.$padding-1;
    gap: v.$margin-5;
    flex: 1;
    overflow: auto;
  }

  .left-section {
    width: 300px;
    min-width: max-content;
    height: fit-content;

    &__list {
      padding: v.$padding-5 0;
    }
  }

  .control-btn {
    height: 56px;
    padding-left: v.$padding-16 - 2;
    font-size: v.$font-size-sm + 1;
    color: c.$black-3;
    border-radius: 0;
    justify-content: flex-start;
    position: relative;

    &.active {
      color: c.$primary;
      font-weight: 700;

      &::before {
        content: "";
        height: 100%;
        border-radius: 0 v.$border-radius-1 v.$border-radius-1 0;
        border-right: 3px solid c.$primary;
        width: 3px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .title {
    display: flex;
    padding-right: v.$padding-1;
    justify-content: space-between;
  }

  .component-wrapper {
    flex: 1;
    padding: v.$padding-1;
    padding-top: v.$padding-15;
    overflow: auto;
    padding-bottom: v.$padding-14 * 2;

    & > div {
      position: unset;
    }
  }
}
