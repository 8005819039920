@use "../../../styles/colors.scss" as c;
@use "../../../styles/variables.scss" as v;

.container {
  height: v.$header-height;
  width: 314px;
  padding: v.$padding-10;
  width: 100%;

  .content {
    background-color: c.$white;
    border-radius: v.$border-radius-3;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    padding: 0 v.$padding-8;
  }

  .admin-info {
    display: flex;
    align-items: center;
  }

  .name-and-role {
    margin-left: v.$margin-9;
    margin-right: v.$margin-5;
  }

  .name {
    font-weight: 700;
    font-size: v.$font-size-sm + 1;
  }

  .avatar-container {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;

    &[class*="ant-dropdown-open"] {
      background-color: red;
    }
  }

  .dropdown-trigger {
    display: flex;
    align-items: center;
    gap: v.$margin-3;
    cursor: pointer;

    .icon {
      transition: all 0.5s ease;
    }

    &[class*="ant-dropdown-open"] .icon {
      transform: scaleY(-1);
    }
  }
}

.dropdown-content {
  padding: v.$padding-5 0;
  background-color: c.$white;
  font-size: v.$font-size-sm;
  box-shadow: 0px 3px 6px rgba(c.$black, 0.2);
  border-radius: v.$border-radius-2;

  &::before {
    content: "";
    width: 6px;
    height: 5px;
    background-color: c.$white;
    position: absolute;
    top: 11px;
    right: 48px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }

  li {
    display: flex;
  }

  .link {
    padding: v.$padding-1 v.$padding-7;
    width: 100%;
  }
}

.overlay {
  min-width: unset !important;

  [class*="ant-dropdown-arrow"] {
    display: none;
  }
}
