@use "../../../../styles/variables.scss" as v;
@use "../../../../styles/colors.scss" as c;

.text {
  font-size: v.$font-size-sm + 1;
  color: c.$gray-medium-dark-2;
  min-width: 118px;
}
.description {
  @extend .text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.email-text {
  @extend .description;
  text-transform: lowercase;
}

.shipping-info {
  @extend .description;
  text-transform: capitalize;
  min-width: 135px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card {
  padding: v.$padding-5;
}

.card-content {
  padding-left: v.$padding-14;
}

.icon {
  background-color: c.$gray-light-10;
  border-radius: v.$border-radius-2;
  padding: v.$padding-1;
}

.profile-pic {
  position: relative;
  width: fit-content;
  margin-bottom: v.$margin-5;

  &__image {
    img {
      border-radius: 50%;
      object-fit: cover;
    }

    height: 50px;
    width: 50px;
  }

  &__edit-icon {
    cursor: pointer;
    position: absolute;
    bottom: 0%;
    right: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 30%);
    background-color: white;

    svg {
      height: 14px;
      width: 14px;
    }
  }
}

[class$="ant-descriptions-row"] > td {
  padding-bottom: v.$padding-2 !important;
}

.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info-row {
  display: flex;
  align-items: baseline;
  margin-bottom: v.$margin-3;
}
