@use "../../styles/variables.scss" as v;
@use "../../styles/colors.scss" as c;

.container {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 1fr 1fr 1fr;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 30px;
  font-size: v.$font-size-sm + 1;
}

.content {
  display: flex;
  flex-direction: column;
}

.image {
  min-width: 120px;
  max-width: 120px;
  min-height: 120px;
  max-height: 120px;
  object-fit: contain;
}

.variant-info {
  font-weight: 300;
  font-size: v.$font-size-sm + (-1);
  width: 100%;
  word-wrap: break-word;
}

.total-price {
  font-weight: 700;
}
