@use "../../../styles/variables.scss" as v;

.details {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 24px;

  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media (max-width: v.$max-breakpoint-sm) {
    padding: v.$padding-7;
  }
}

.btn {
  padding-right: v.$padding-11 !important;
  padding-left: v.$padding-11 !important;
}

.row {
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 25px;

  @media (max-width: v.$max-breakpoint-xl) {
    display: flex;
    flex-direction: column;
    grid-gap: 0px;
  }
}

.btn-container {
  position: bsolute;
  align-self: flex-end;
  bottom: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
}

.small-row {
  display: flex;
  justify-content: space-between;
}

@media (max-width: v.$max-breakpoint-sm) {
  .container {
    padding: v.$padding-7;
  }

  .row {
    display: flex;
    flex-direction: column;
    grid-gap: 0px;
  }
}

.title-row {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: v.$font-size-xxl;
  font-weight: 600;
}
