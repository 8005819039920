@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;

.modal {
  cursor: auto;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 60%;

  border-radius: v.$border-radius-3;
  background-color: c.$white;
}

.error {
  margin-top: v.$margin-4;
  color: c.$primary;
}
