@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;

.col {
  margin-bottom: v.$margin-6;
}

.container {
  background-color: c.$gray-light-6;
  box-shadow: none;
  padding: v.$padding-8 v.$padding-10;
}

.description {
  color: c.$header-light-gray;
}

.info-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: v.$margin-4;
}

.title {
  font-size: v.$font-size-sm + 1;
  text-transform: uppercase;
  color: c.$black-3;
  font-weight: 700;
  margin-bottom: v.$margin-1;
}

.item {
  display: flex;
  gap: v.$margin-1 + 1;
  align-items: center;
  text-transform: capitalize;

  &:not(:last-child) {
    padding-right: v.$padding-4;
    border-right: 1px solid c.$header-dark-gray;
  }

  &:not(:first-child) {
    padding-left: v.$padding-4;
  }
}

.link {
  color: c.$header-light-gray;
  text-decoration: underline;
}

.tag {
  margin: 0;
}
