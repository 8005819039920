@use "../../../../styles/colors.scss" as c;
@use "../../../../styles/variables.scss" as v;

.container {
  display: flex;
  flex-direction: column;
  gap: v.$margin-3;
}

.table {
  [class$="ant-table-content"] {
    height: 300px;
    overflow: auto;
    background: unset;
  }

  table {
    border-collapse: separate !important;
    background: unset;

    tbody {
      tr {
        border-radius: v.$border-radius-2;
        padding: v.$margin-4 0 !important;
        box-shadow: 0px 2px 6px 1px c.$gray-light-7 !important;
        background: unset !important;

        td {
          padding: v.$padding-5 v.$padding-8 v.$padding-5 v.$padding-6 !important;
          //To fix checkbox issue
          &:first-child {
            span {
              border-spacing: initial;
            }
          }
        }
      }
    }
  }
}

.product {
  display: flex;
  align-items: center;
  gap: v.$margin-3;

  img {
    min-height: 60px;
    min-width: 60px;
    max-width: 60px;
    max-height: 60px;
    object-fit: contain;
    border-radius: 0 !important;
  }
}

.price {
  display: flex;
  gap: v.$margin-3;

  &__value {
    color: v.$font-size-md;
    font-weight: 600;
  }
}

.quantity-wrapper {
  display: flex;
  gap: v.$margin-1;
  align-items: center;
  font-size: v.$font-size-sm + 1 !important;
  > div {
    width: auto;
  }
}
