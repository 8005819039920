@use "../../../../styles/colors.scss" as c;
@use "../../../../styles/variables.scss" as v;

.container {
  font-size: v.$font-size-sm + 1;
  color: c.$gray-medium-dark-2;
}

.card {
  width: 100%;

  background-color: c.$gray-light-12;
  padding: v.$padding-5;

  border: 1px solid c.$gray-light-8 !important;
  box-shadow: none !important;
}

.header {
  display: grid;
  grid-template-columns: 6fr 2fr 1fr 1fr;

  div {
    color: c.$gray-medium-dark;
    font-weight: 600;
  }

  div:last-child {
    margin-left: auto;
  }
}

.hr {
  height: 1px;
  background-color: c.$gray-light-11;
  margin: v.$margin-3 0;
}

.item {
  color: c.$gray-medium-dark;

  margin: v.$margin-3 0;
  display: grid;
  grid-template-columns: 6fr 2fr 1fr 1fr;

  div:last-child {
    margin-left: auto;
  }
}

.subtotal-section {
  display: grid;
  grid-template-columns: 6fr 4fr;
  margin-top: v.$margin-6;

  &__item {
    display: flex;
    justify-content: space-between;
  }
}
