@use "../../styles/variables.scss" as v;

.dropdown {
  position: absolute;
  top: calc(100% + 12px);
  background: white;
  box-shadow: 0 0 12px rgba(black, 0.2);
  width: 180px;
  &.right {
    right: 0;
    &:before {
      right: 38px;
      box-shadow: 0 0 12px rgba(black, 0.2);
    }
  }

  &.left {
    left: 0;
    &:before {
      left: 35px;
    }
  }

  &:before {
    content: "";
    background: #fff;
    position: absolute;
    top: -6px;
    width: 13px;
    height: 13px;
    transform: rotate(45deg);
    z-index: 0;
  }

  &-section {
    position: relative;
    display: inline-flex;
  }

  &-container {
    background-color: #fff;
    border-radius: 12px;
    position: relative;
    z-index: 1;

    &:not(.padding--none) {
      padding-bottom: v.$margin-3;
    }
  }
}
