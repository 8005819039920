@use "../../../styles/variables.scss" as v;
@use "../../../styles/colors.scss" as c;

.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: v.$padding-9 v.$padding-10 + 2;
  margin-bottom: 32px;
}

.title {
  color: c.$gray-medium-dark-2;
  font-size: 15px;
}

.value {
  color: c.$black-2;
  font-size: v.$font-size-xxl;
  font-weight: bold;
}

.svgWrapper {
  &-blue {
    background-color: #63e2f126;
    z-index: 0;
    width: 94px;
    height: 94px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  }

  &-green {
    background-color: #28c66f26;
    z-index: 0;
    width: 94px;
    height: 94px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  }

  &-red {
    background-color: #ec545326;
    z-index: 0;
    width: 94px;
    height: 94px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  }

  .svgChild {
    z-index: 2;
  }
}
