@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;

.container {
  margin: v.$margin-7 0;
  color: c.$gray-medium-dark-2;
  font-size: v.$font-size-sm + 1;
}

.card {
  box-shadow: none !important;
}

.row {
  display: flex;
  justify-content: space-between;
}

.header-section {
  margin-bottom: v.$margin-4;
}

.icon-row {
  margin-bottom: v.$margin-4;
}

.icon {
  display: flex;
  align-items: center;
  gap: v.$margin-1;
  text-align: center;
  color: c.$black;
  font-weight: 600;
  font-size: v.$font-size-xl;
  img {
    max-height: 128px;
  }
}

.invoice {
  font-weight: 600;
  font-size: v.$font-size-xl;
  color: c.$black;
  text-transform: uppercase;
}

.hr {
  height: 2px;
  background-color: c.$gray-light-1;
  margin-bottom: v.$margin-4;
}

.hr-small {
  height: 2px;
  background-color: c.$gray-light-1;
  margin: v.$margin-1 0;
}

.row-grid {
  display: grid;
  grid-template-columns: 3fr 2fr;
}

.items-grid {
  display: grid;

  grid-template-columns: 6fr 1.33fr 1.33fr 1.33fr;
  margin: v.$margin-3 0px;

  &__last {
    margin-left: auto;
  }
}

.total-section {
  margin: v.$margin-13 0px v.$margin-8 auto;
  width: 26.67%;
}

.total-grid {
  display: flex;
  justify-content: space-between;
}

.address-section {
  display: grid;
  grid-template-columns: 3fr 2fr;
  margin: v.$margin-9 0;

  > div {
    display: flex;
    flex-direction: column;
    gap: v.$margin-2;
  }
}

.date {
  > div {
    display: flex;
    justify-content: space-between;
    gap: v.$margin-9;
  }
}

.items-container {
  margin-top: v.$margin-7;
}

.note {
  font-size: v.$font-size-sm + 1;
}

.address {
  &__phone {
    display: grid;
    grid-template-columns: 1fr 9fr;
  }
}
