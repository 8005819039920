@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;

.container {
  //max-height: 100%;
  .title {
    font-size: v.$font-size-lg;
    color: c.$black-3;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: v.$margin-4;
  }

  .settings-sections {
    display: flex;
    flex-direction: column;
    row-gap: v.$margin-6;
  }
}
