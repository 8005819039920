@use "../../../../styles/colors.scss" as c;
@use "../../../../styles/variables.scss" as v;

.container {
  width: 75%;
  margin: auto;

  display: flex;
  flex-direction: column;
  gap: v.$margin-6;
}

.client-details {
  width: 100%;
}

.subtitle {
  font-size: v.$font-size-xl;
  font-weight: bold;
  color: c.$gray-dark-1;
}

.topcards {
  display: flex;
  width: 100%;
  margin-left: 0 auto;
  gap: v.$margin-5 + 2;
  margin-bottom: v.$margin-6;
}

.topcard {
  width: 50%;
}

.full-width-card {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: v.$margin-10;
}
