@use "./colors.scss" as c;
@use "./variables.scss" as v;

.ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: v.$border-radius-2 + 2;
  border: none;
  margin: 0;
  .ant-upload {
    flex-direction: column;
  }
}
