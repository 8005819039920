@use "./variables.scss" as v;
@use "./colors.scss" as c;

.ant-spin-spinning {
  margin: v.$margin-5 auto 0;
  display: block;

  .ant-spin-dot-item {
    background-color: c.$primary;
  }
}
