@use "../../../styles/variables.scss" as v;
@use "../../../styles/colors.scss" as c;

.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
  border: 1px solid c.$gray-light-9;
  border-radius: 5px;
  font-size: v.$font-size-xs + 2 !important;
}

.ant-select-selection-placeholder {
  flex: 1;
  overflow: hidden;
  color: c.$gray-medium-dark-2 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: c.$black !important;
  font-size: 14px !important;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}
