@use "../../styles/variables.scss" as v;

.container {
  width: 60%;
  margin: 0 auto;

  @media (max-width: v.$max-breakpoint-xl) {
    width: 70%;
  }

  @media (max-width: v.$max-breakpoint-lg) {
    width: 80%;
  }

  @media (max-width: v.$max-breakpoint-md) {
    width: 90%;
  }
}
