@use "../../styles/variables.scss" as v;
@use "../../styles/colors.scss" as c;

.container {
  position: sticky;
  z-index: 100;
  top: 0;
  background-color: c.$gray-background;
}

.header {
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
  }

  h1 {
    margin: 0;
    margin-left: v.$margin-2;
    text-transform: uppercase;
    font-size: v.$font-size-xxl;
    color: c.$black-2;
    font-weight: bold;
    margin-bottom: 0;
  }

  button {
    margin-left: auto;
  }
}

.breadcrumb {
  color: c.$gray-2;
  font-size: v.$font-size-xs;
  margin-left: v.$margin-7 + 3;
}
