@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;

.container {
  display: flex;
  max-width: 100vw;

  .header-and-content {
    background-color: c.$gray-background;
    width: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .content {
    position: relative;
    height: calc(100vh - v.$header-height);
    overflow-y: auto;
    scrollbar-gutter: stable;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 0 v.$padding-10 v.$padding-16;

    //Overriding AntD Card styling
    > div {
      border: none;
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  ::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }
}
