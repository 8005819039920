@use "../../../../../../styles/variables.scss" as v;
@use "../../../../../../styles/colors.scss" as c;

.indexrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: c.$black-3;
  font-weight: bold;

  & button {
    position: absolute;
    top: 6%;
    right: 5%;
    width: 14%;
    border: 0.5px solid c.$gray-medium-dark-3;
    border-radius: 9%;
    color: c.$header-light-gray;

    &:hover {
      cursor: pointer;
    }
  }
}

.productCol {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 18px;

  & img {
    width: 10%;
    max-height: 150px;
    object-fit: contain;
  }
}

.nameatr {
  width: 80%;
  padding-left: 30px;
  .name {
    margin-top: 30px;
  }

  .namenoatr {
    margin-top: 6px;
  }

  .attr {
    margin-top: -30px;
    font-size: v.$font-size-xs + 2;
    color: c.$gray;
    width: 80%;
  }
}

.dlt-btn {
  margin-bottom: 10px;
  background-color: transparent;
  border: none;
  height: fit-content;
  width: fit-content;

  &:hover {
    cursor: pointer;
  }
}

.nmbr-input {
  height: 30px;
  width: 84px;
  border: 0.5px solid c.$black-3;
  border-radius: 5px;
  margin-top: -15px;
  padding-left: 8px;
}

.price-row {
  &-bold {
    font-weight: bold;
    width: 20%;
  }
}

.total {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: v.$font-size-sm + 1;
  border-top: 1px solid c.$gray-2;
  padding-top: 10px;
  margin-bottom: -20px;
}

.bottomCol {
  padding-left: 50%;
  margin-bottom: v.$margin-5;
}

.bottomRow {
  display: flex;
  justify-content: space-between;
}

.productrow {
  display: flex;
  justify-content: space-between;

  .prdname {
    width: 30%;
    margin-right: -20%;
  }
}

.card {
  padding: v.$padding-6;
}
