@use "../../../../styles/colors.scss" as c;
@use "../../../../styles/variables.scss" as v;

.custom-card {
  box-shadow: none !important;
  border-radius: v.$border-radius-2 !important;
  color: c.$black-3;
  padding: v.$padding-5 + 1;
}

.container {
  color: c.$header-light-gray;
}
