@use "../../../../styles/variables.scss" as v;
@use "../../../../styles/colors.scss" as c;

.form {
  display: flex;
  flex-direction: column;

  &__sections {
    display: flex;
    flex-direction: column;
    gap: v.$margin-6;
    &__section {
      &__title {
        font-size: v.$font-size-xl;
        font-weight: 700;
      }
    }
  }

  &__header {
    position: absolute;
    top: -6px;
    z-index: 10;
    background-color: c.$gray-background;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: -webkit-fill-available;
    width: -moz-available;

    &__submit {
      width: fit-content !important;
    }
  }
}

.visibility {
  display: flex;
  align-items: center;
  gap: v.$margin-4;
  &__label {
    text-transform: uppercase;
    font-size: v.$font-size-sm;
    font-weight: 700;
  }
}
