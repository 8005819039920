@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;

.table {
  [class="ant-table-content"] {
    overflow: auto;
    padding: 6px;
    background: c.$gray-background;
  }

  [class*="ant-table-container"]::before {
    box-shadow: none !important;
  }

  [class*="ant-table-content"] {
    overflow: auto hidden;
  }

  table {
    background: c.$gray-background;
    border-spacing: 0 v.$margin-4 !important;
    table-layout: auto !important;
    width: 100% !important;

    th[class*="ant-table-cell-fix-right"][style*="text-align: center"] {
      div[class*="ant-table-column-sorters"] {
        justify-content: center;
      }
    }
    th[class*="ant-table-cell-fix-left"][style*="text-align: center"] {
      div[class*="ant-table-column-sorters"] {
        justify-content: center;
      }
    }

    [class*="ant-table-column-sorters"] {
      background: inherit;
      justify-content: flex-start;

      > span:nth-child(2) {
        margin-left: v.$margin-2; // this is the sorters
      }

      [class*="ant-table-column-title"] {
        width: fit-content;
        flex: 0;
      }

      // override sort svg color;
      [class*="ant-table-column-sorter-inner"] {
        &:not(.active) {
          color: c.$gray-dark-1;
        }
      }
    }

    [class*="ant-table-cell"] {
      &:last-child {
        button {
          border: none;
          box-shadow: none;
          background: inherit;

          &::after {
            box-shadow: none !important;
          }

          &:hover,
          &:focus {
            background: inherit;
            border-color: inherit;
          }
        }
      }

      &[class*="ant-table-cell-row-hover"] {
        background: c.$gray-light-5 !important;
      }
    }

    th,
    td {
      &:first-child {
        border-top-left-radius: v.$border-radius-2 !important;
        border-bottom-left-radius: v.$border-radius-2 !important;
      }

      &:last-child {
        border-top-right-radius: v.$border-radius-2 !important;
        border-bottom-right-radius: v.$border-radius-2 !important;

        // ACTIONS COLUMN!
        &[class*="ant-table-cell-fix-right"] {
          width: 62px;
          right: -6px !important;
        }
      }
    }

    thead {
      position: relative;

      tr {
        background: c.$gray-light-5;
        border-radius: v.$border-radius-2;
        box-shadow: v.$shadow-1;

        th {
          &[class*="ant-table-cell"] {
            border: none;
            font-weight: normal;
            padding: v.$padding-1 v.$padding-5;
            background: c.$gray-light-5 !important;

            [class*="ant-table-column-title"] {
              color: c.$black-2;
              font-size: v.$font-size-sm;
              font-weight: normal;
              white-space: nowrap;
            }

            &:not(:last-child) {
              &::before {
                display: none;
              }
            }
          }

          &[class*="ant-table-column-has-sorter"][style="text-align: center;"] {
            & [class*="ant-table-column-sorter"] {
              justify-content: center;
            }
          }
        }
      }
    }

    tbody tr[class*="ant-table-row"] {
      border-radius: v.$border-radius-2;
      padding: v.$padding-4 v.$padding-5;
      background: c.$gray-light-5;
      &:not([class*="ant-table-placeholder"]) {
        box-shadow: v.$shadow-1;
      }

      &:hover > td {
        background: c.$white;
      }

      td {
        border: none;
        background: c.$gray-light-5;
        padding: v.$padding-4 v.$padding-5;
      }
    }

    span {
      font-size: v.$font-size-sm;
      // color: c.$black-2;
    }

    td:first-child {
      vertical-align: middle;
      white-space: nowrap;
    }

    img {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      //   margin-right: v.$margin-4;
    }
  }

  &::-webkit-scrollbar {
    display: block;
  }
}
