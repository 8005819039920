@use "../../../../styles/colors.scss" as c;
@use "../../../../styles/variables.scss" as v;

.container {
  display: flex;
  gap: v.$margin-3;
}

.card {
  width: 100%;
  background-color: c.$gray-light-12;
  padding: v.$padding-5 v.$padding-7;

  border: 1px solid c.$gray-light-8 !important;
  box-shadow: none !important;

  height: fit-content;
}

.address-container {
  display: flex;
  flex-direction: column;
  gap: v.$margin-2;

  font-size: v.$font-size-sm + 2;
  color: c.$gray-medium-dark-2;
}

.title {
  font-weight: 600;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name {
  display: flex;
  gap: v.$margin-3;

  img {
    height: 35px;
    width: 35px;
    border-radius: 50%;
  }
}

.company {
  display: flex;
  gap: v.$margin-8;
  color: c.$gray-medium-dark-2;
  font-weight: 600;
  margin: v.$margin-1 0;
}

.phone {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
