@use "../../styles/variables.scss" as v;
@use "../../styles/colors.scss" as c;

.filters-wrapper {
  &[class*="ant-radio-group"] {
    margin-bottom: 0;
    background: c.$white;
    border-radius: v.$border-radius-2;
    display: flex;
    border: 1px solid c.$gray-light-9;

    label {
      display: flex;
      align-items: center;

      &[class*="ant-radio-button-wrapper"] {
        height: 38px;
        border-color: transparent;
        border-radius: v.$border-radius-2;
        background: c.$white;
        color: c.$black;

        &:not(.ant-radio-button-wrapper-disabled):first-child {
          border-color: transparent;
        }

        &:hover {
          color: c.$primary;
          border-color: transparent;
        }

        &:first-child {
          border-top-left-radius: v.$border-radius-2;
          border-bottom-left-radius: v.$border-radius-2;
        }

        &:last-child {
          border-top-right-radius: v.$border-radius-2;
          border-bottom-right-radius: v.$border-radius-2;
        }

        &::before {
          width: 0 !important;
        }
      }

      &[class*="ant-radio-button-wrapper-checked"] {
        background: c.$primary;
        border-color: c.$primary;
        color: c.$white;

        &:focus-within {
          box-shadow: none;
        }

        &:hover {
          color: c.$white;
        }
      }
    }
  }
}
