@use "../../styles/colors.scss" as c;
@use "../../styles/variables.scss" as v;

.container {
  display: flex;
  flex-direction: column;
  position: relative;

  .title {
    font-size: v.$font-size-xxl;
    color: c.$black-2;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .cards-wrapper {
    display: flex;
    margin-left: v.$margin-1 * -1;
    margin-right: v.$margin-2 * -1;
    padding-left: v.$padding-1;
    gap: v.$margin-5;
    overflow: auto;
  }

  .control-panel {
    width: 300px;
    height: fit-content;
    padding: v.$padding-5 0;
  }

  .control-btn {
    height: 56px;
    padding-left: v.$padding-9;
    font-size: v.$font-size-sm + 1;
    color: c.$black-3;
    border-radius: 0;
    justify-content: flex-start;
    position: relative;
    font-weight: 700;

    &.active {
      color: c.$primary;
      font-weight: 700;

      &::before {
        content: "";
        height: 100%;
        border-radius: 0 v.$border-radius-1 v.$border-radius-1 0;
        border-right: 3px solid c.$primary;
        width: 3px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .back-and-submit {
    display: flex;
    margin-bottom: v.$margin-4;
    gap: v.$margin-3;
    align-items: center;

    .back-btn {
      padding: 0;
      color: c.$gray-dark-1;
      height: 24px;

      &:hover {
        background-color: c.$white;
      }
    }
  }

  .variant-btn {
    @extend .control-btn;
    font-weight: 400;
    padding-left: v.$padding-16;

    &.active {
      font-weight: 400;

      &::before {
        content: unset;
      }
    }
  }

  .menu {
    border: none !important;

    * {
      transition: none !important;
    }

    i {
      display: none;
    }

    [class*="ant-menu-item-selected"]::after {
      display: none;
    }

    [class*="ant-menu-submenu-title"],
    [class*="ant-menu-item"] {
      // @extend .link;
      padding-right: 0 !important;
      height: unset !important;
      width: 100% !important;

      & > span {
        margin: 0;
      }

      &:hover {
        color: c.$gray-darker;
      }

      &:active {
        background-color: c.$white;
      }
    }

    [class*="ant-menu-inline"] {
      background-color: c.$white;
      max-height: 300px;
      overflow-y: auto;

      & > li {
        padding: 0 !important;
        margin-top: 0;

        &:active {
          background-color: c.$white;
        }

        &:not(:last-child) {
          margin-bottom: v.$margin-2;
        }

        & > span {
          display: flex;
        }
      }
    }
  }

  .form-wrapper {
    flex: 1;
    padding: v.$padding-2;
    padding-top: 0;
    overflow: auto;
    padding-bottom: v.$padding-14 * 2;

    & > div {
      position: unset;
    }
  }
}
