@use "./colors.scss" as c;
@use "./variables.scss" as v;

.ant-input-number {
  border: none !important;
  outline: none;
  background-color: transparent;

  &-input {
    border-radius: v.$border-radius-2;
    border: 1px solid $gray-dark;
    color: $gray-darker;
    font-size: v.$font-size-sm;
    min-height: 38px;
    padding: v.$padding-1 + 1 v.$padding-5;
    @include v.transition-colors;
    position: relative;

    &::placeholder {
      color: $header-light-gray;
      text-transform: capitalize;
    }

    &:focus {
      border: 1px solid $black;
      outline: none !important;
    }

    &:hover {
      border: 1px solid $black;
    }
  }

  &:hover,
  &:focus,
  &-focused {
    border: none;
    outline: none;
    box-shadow: none !important;
  }
}

.ant-input-number-input {
  background-color: c.$white;
}

.ant-input-group-addon {
  background-color: transparent;
}
