@use "../../../../styles/variables.scss" as v;
@use "../../../../styles/colors.scss" as c;

.roles-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.column-header-name {
  padding-left: v.$padding-15 + 2;
}

.avatar {
  height: 42px !important;
  width: 42px !important;
  object-fit: cover;
}
