@use "../../../../styles/variables.scss" as v;
@use "../../../../styles/colors.scss" as c;

.info-card {
  padding: v.$padding-4 v.$padding-8;
  font-size: v.$font-size-sm + 1;
  font-weight: 700;
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: v.$margin-4 + 1;
  }

  .card-item {
    width: 100%;
  }
}

.edit-button {
  border-radius: 6px;
  height: 30px;
}
