@use "../../styles/variables.scss" as v;

@import "../../styles/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  label {
    font-weight: 700;
    font-size: v.$font-size-md;
    text-transform: uppercase;
    color: $gray-dark;
  }

  button {
    align-items: center;
    background-color: unset;
    border-radius: v.$border-radius-2;
    border: 1px solid $gray-dark;
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: center;
    font-family: "Open Sans";
    font-size: v.$font-size-sm;
    margin-top: v.$margin-2 - 2;
    padding: 0 v.$padding-5;
    padding-right: v.$padding-3;
    position: relative;
    text-align: left;
    height: 38px;
    @include v.transition-colors;

    &:focus {
      border: 1px solid $black;
      outline: none !important;
    }

    &:hover {
      color: $black;
      background-color: unset;
    }

    span {
      flex: 1;
      color: $header-light-gray;
    }

    .down-chevron {
      height: v.$padding-4;
      width: v.$padding-4;
      fill: $gray-light-3;
      transition: transform 0.5s ease;
    }

    .up-chevron {
      @extend .down-chevron;
      transform: scale(-1);
    }
  }

  .options-container {
    position: relative;
    width: 100%;
    z-index: 4;

    ul {
      @include v.transition-colors;
      background-color: $gray-background;
      border-radius: v.$border-radius-2;
      border: 1px solid $gray;
      display: flex;
      flex-direction: column;
      list-style: none;
      left: 0px;
      margin: 0px;
      max-height: 4 * (v.$font-size-sm + 2 * v.$padding-4 + 1px);
      overflow: hidden auto;
      padding: 0px;
      position: absolute;
      text-align: left;
      top: v.$margin-2;
      width: 100%;
      box-shadow: v.$shadow-1;

      &:focus {
        border: 1px solid $gray;
        outline: none !important;
      }

      li {
        @include v.transition-colors;
        cursor: pointer;
        color: $gray-darker;
        position: relative;
        line-height: v.$font-size-sm;
        font-size: v.$font-size-sm;
      }
    }

    &.single {
      li {
        padding: v.$padding-4 v.$padding-5;

        &:not(:last-child) {
          border-bottom: 1px solid $gray-light-1;
        }

        &:last-child {
          border-bottom: none;
        }

        &.selected {
          background-color: $gray-light-3;
        }

        &.active {
          background-color: $gray-light-2;
        }

        &.disabled {
          background-color: $gray-light-4;
          color: $gray-light-3;
          cursor: not-allowed;
        }
      }
    }

    &.multiple ul {
      padding: v.$padding-4 v.$padding-5;

      li {
        padding: v.$padding-2 v.$padding-9;

        &:before {
          content: "";
          position: absolute;
          top: 7px;
          left: 0;
          display: block;
          margin: 0;
          width: 18px;
          height: 18px;
          border: 1px solid $gray-light-2;
          border-radius: v.$border-radius-1;
          transition: border-color 0.2s ease, background-color 0.2s ease;
        }

        &:after {
          content: "";
          position: absolute;
          top: 9px;
          left: 7px;
          display: block;
          width: 4px;
          height: 10px;
          transform: rotate(45deg);
        }

        &.selected {
          &:before {
            border-color: $white;
            background-color: $primary;
          }

          &:after {
            border-right: 2px solid $white;
            border-bottom: 2px solid $white;
          }
        }

        &.disabled {
          background-color: $gray-light-4;
          color: $gray-light-3;
          cursor: not-allowed;
        }
      }
    }
  }
}

.selected-items {
  margin-top: v.$margin-4;
  display: flex;
  overflow-x: auto;
}

.placeholder {
  color: $gray-medium-dark;
  text-transform: capitalize;
}

.error {
  color: $red;
  font-size: v.$font-size-sm;
  margin-top: v.$margin-1;
  margin-left: v.$margin-1;
  visibility: hidden;

  &--visible {
    visibility: visible;
  }
}

.description {
  color: $gray-medium-dark;
  font-size: v.$font-size-sm;
  margin: 0;
}

.leave-transition {
  @include v.transition-opacity;
}

.leave-from {
  opacity: 1;
}

.leave-to {
  opacity: 0;
}

.enter-transition {
  @include v.transition-opacity;
}

.enter-from {
  opacity: 0;
}

.enter-to {
  opacity: 1;
}
