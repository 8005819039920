@use "./colors.scss" as c;
@use "./variables.scss" as v;

body {
  overflow: hidden;
}

.x-ui-admin {
  .logo {
    height: 32px;
    margin: 16px;
    text-align: center;
    img {
      height: 32px;
    }
  }

  .x-ui-admin-header {
    background: #fff;
    padding: 0;
    text-align: right;
    padding-right: v.$padding-5;
  }

  .x-ui-admin-footer {
    font-family: monospace;
    color: #555;
    text-transform: uppercase;
  }
}

.ant-descriptions-item-label {
  font-weight: 500;
}
