@use "../../styles/variables.scss" as v;
@use "../../styles/colors.scss" as c;

.container {
  display: grid;
  grid-template-columns: 3fr 9fr;
  grid-column-gap: 50px;
}

.content {
  display: flex;
  flex-direction: column;
}

.product-image {
  max-width: 200px;
  max-height: 200px;
  min-width: 200px;
  min-height: 200px;
  object-fit: contain;
}

.title {
  font-size: v.$font-size-lg;
  font-weight: 600;
  margin-bottom: v.$margin-2;
}

.description {
  margin: v.$margin-5 0px;
  font-size: v.$font-size-sm;
}

.price {
  display: flex;
  gap: 12px;
  font-weight: 600;

  &__new {
    font-size: v.$font-size-lg;
    color: c.$primary;
  }
  &__old {
    text-decoration: line-through;
    color: c.$gray-subdued;
  }
}
