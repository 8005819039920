@use "../../../../styles/variables.scss" as v;
@use "../../../../styles/colors.scss" as c;

.container {
  h2 {
    font-size: v.$font-size-xl - 2;
    margin-top: v.$margin-1;
    text-transform: uppercase;
  }

  .btn {
    padding: 0;
    background-color: transparent;
    color: c.$gray-dark-1;
    font-weight: 700;
  }

  .add-btn {
    @extend .btn;
    margin-top: v.$margin-4;
  }

  .remove-btn {
    @extend .btn;
    position: absolute;
    bottom: -2px;
    right: 0;
  }

  .shipment-wrapper {
    padding-bottom: v.$padding-11;
    position: relative;

    [class*="row"] {
      margin-left: -12px !important;
      margin-right: -12px !important;

      & > div {
        padding: 0 12px !important;
      }
    }
  }
}
.page-wrapper {
  max-width: 810px;
  margin: 0 auto;
}

.header {
  font-size: v.$font-size-xxl;
  font-weight: 700;
  text-transform: uppercase;
  color: c.$gray-darker;
}
