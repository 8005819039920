@use "../../../../styles/colors" as c;
@use "../../../../styles/variables" as v;

.table {
  table {
    thead {
      tr {
        th:nth-child(3) {
          > div {
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: v.$padding-4 v.$padding-5 !important;
        }
      }
    }
  }
}

.name-column {
  display: flex;
  align-items: center;

  img {
    height: 55px;
    width: 55px;
    margin-right: v.$margin-2 !important;
    object-fit: cover;
  }
}

.name-and-desc {
  max-width: calc(100% - 50px);
}

.name {
  margin-bottom: 0;
  font-size: v.$font-size-sm;
  color: c.$black-2;
  width: fit-content;
}

.desc {
  margin-bottom: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.title {
  font-size: v.$font-size-lg;
  font-weight: bold;
  text-transform: uppercase;
}

.header {
  display: flex !important;
  justify-content: space-between;
}
