@use "../../../../../styles/colors.scss" as c;
@use "../../../../../styles/variables.scss" as v;

.nested-table {
  tbody {
    padding-bottom: 0;
  }

  tr {
    box-shadow: none !important;
    border-radius: 0 !important;
    border-spacing: 0;
  }

  &::before,
  &::after {
    display: none !important;
  }

  [class*="ant-table-has-fix-right"] {
    margin: 0 !important;
  }

  [class="ant-table"] {
    margin: 0 !important;
  }
}

.expanded-row {
  margin-top: v.$margin-2 * -1;
  padding-top: v.$padding-2 !important;
  padding-bottom: v.$padding-4 !important;
  background-color: c.$white;
  border-spacing: 0;
  overflow: hidden;
  border-radius: 0 0 v.$border-radius-2 v.$border-radius-2;
  box-shadow: 0px 3px 6px c.$gray-light-7 !important;

  & > td:first-child {
    padding: 0 !important;
  }
}
