@use "../../../../styles/variables.scss" as v;

.form {
  display: flex;
  flex-direction: column;

  &__sections {
    display: flex;
    flex-direction: column;
    gap: v.$margin-6;
    &__section {
      &__title {
        font-size: v.$font-size-xl;
        font-weight: 700;
      }
    }
  }

  &__header {
    margin-top: -(v.$padding-15);
    padding-bottom: v.$padding-2;
    display: flex;
    justify-content: space-between;

    &__submit {
      width: fit-content !important;
    }
  }
}

.visibility {
  display: flex;
  align-items: center;
  gap: v.$margin-4;
  &__label {
    text-transform: uppercase;
    font-size: v.$font-size-sm;
    font-weight: 700;
  }
}
