@use "../../../styles/variables.scss" as v;
@use "../../../styles/colors.scss" as c;

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: v.$padding-9 v.$padding-10 + 2;
  margin-bottom: 32px;
  padding-bottom: 40px;
  width: 100%;
  max-height: 386px;
  margin-top: 1rem;

  & canvas {
    padding-top: 20px;
    max-height: 267px;
  }
}

.title {
  color: c.$black-2;
  font-size: v.$font-size-xl;
}

.btns {
  display: flex;
  align-items: center;
  gap: 16px;

  & button {
    font-size: v.$font-size-sm;
    color: c.$gray-medium-dark-2;
    background-color: transparent;
    border: 1px solid c.$gray-light-9;
    border-radius: 10px;
    width: 85px;
    height: 38px;
  }

  .btn-active {
    background-color: c.$gray-light-4;
  }
}

.daypicker,
.daypicker:hover,
.daypicker:active {
  width: 50%;
  margin-top: -5px;

  & > div {
    border-style: none;

    & > div {
      border: 1px solid c.$gray-light-9;

      & div {
        border-style: none;
      }
    }

    & > svg {
      margin-top: -22px;
    }
  }
}

.value {
  color: c.$black-2;
  font-size: v.$font-size-xxxl + 4;
  font-weight: bold;
}

.indexrow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 18px;
}
