@use "../../../../styles/variables.scss" as v;
@use "../../../../styles/colors.scss" as c;

.container {
  padding-bottom: v.$padding-14;
  margin-left: v.$margin-1 * -1;
  padding-left: v.$padding-1;
  overflow: auto;
  .btn {
    padding: 0;
    background-color: transparent;
    color: c.$gray-dark-1;
    font-weight: 700;
  }

  .add-btn {
    @extend .btn;
    margin-top: v.$margin-1;
  }

  .remove-btn {
    @extend .btn;
    position: absolute;
    bottom: -2px;
    right: 0;
  }

  .shipment-wrapper {
    padding-bottom: v.$padding-11;
    position: relative;

    [class*="row"] {
      margin-left: -8px !important;
      margin-right: -8px !important;

      & > div {
        padding: 0 v.$padding-2 !important;
      }
    }
  }
}

.info-card {
  padding: v.$padding-4 v.$padding-8;
  font-size: v.$font-size-sm + 1;
  font-weight: 700;
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: v.$margin-4 + 1;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 902px;
}
.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-header {
  max-width: 902px;
  padding: v.$padding-5 0 !important;
  width: 100%;
}
