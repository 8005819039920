@use "../../styles/variables.scss" as v;
@use "../../styles/colors.scss" as c;

.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .input-container {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin-top: v.$margin-1 + 1;
  }

  .description {
    color: c.$gray-medium-dark;
    font-size: v.$font-size-sm;
    margin: 0;
  }

  label {
    font-weight: 700;
    font-size: v.$font-size-sm;
    text-transform: uppercase;
    color: c.$gray-dark;
  }
}
