@use "../../../../styles/colors.scss" as c;
@use "../../../../styles/variables.scss" as v;

.container {
  display: flex;
  flex-direction: column;
  gap: v.$margin-5;
}

.table {
  [class$="ant-table-content"] {
    height: 350px;
    overflow: auto;
    background: unset;
  }

  table {
    border-collapse: separate !important;
    background: unset;

    tbody {
      tr {
        border-radius: v.$border-radius-2;
        padding: v.$margin-4 0 !important;
        box-shadow: 0px 2px 6px 1px c.$gray-light-7 !important;
        background: unset !important;

        td {
          padding: v.$padding-5 v.$padding-5 !important;
        }
      }
    }
  }
}

.client-name-pic {
  display: flex;
  align-items: center;

  img {
    height: 35px;
    width: 35px;
    border-radius: 50%;
  }
}

.order-number {
  display: flex;
  align-items: center;
  gap: v.$margin-1;
}
